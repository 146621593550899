// Copied from https://codesandbox.io/s/popper-with-arrow-58jhe with changes for v5
// v5 CSS from https://stackoverflow.com/questions/57500324/example-of-using-arrow-in-popper-component-of-material-ui
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
  styled,
} from '@mui/material';
import React, { ReactElement, useState } from 'react';

interface Props {
  content: ReactElement;
  children: ReactElement;
  placement?: PopperPlacementType;
  onHover?: () => void;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 2000,
  '&[data-popper-placement*="bottom"] #arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  '&[data-popper-placement*="top"] #arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] #arrow': {
    left: 0,
    marginLeft: '-0.9em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] #arrow': {
    right: 0,
    marginRight: '-0.9em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
}));

const Arrow = styled('span')({
  overflow: 'hidden',
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
});

const TooltipBtn = styled('div')({ '.MuiSvgIcon > *': { width: 24, height: 24 } });

function RichTooltip({ placement = 'bottom', content, children, onHover = () => {} }: Props) {
  const [open, setOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState(null);
  const [childNode, setChildNode] = useState<HTMLElement | null>(null);

  const handleToggle = (e) => {
    setChildNode(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleOpen = (e, newOpen) => {
    setChildNode(e.currentTarget);
    setOpen(newOpen);
  };

  return (
    <div>
      <TooltipBtn
        role="button"
        onFocus={(e) => {
          handleOpen(e, true);
          onHover();
        }}
        onMouseOver={(e) => {
          handleOpen(e, true);
          onHover();
        }}
        onBlur={(e) => {
          handleOpen(e, false);
        }}
        tabIndex={0}
        onKeyDown={handleToggle}
        onClick={handleToggle}
      >
        {children}
      </TooltipBtn>
      <StyledPopper
        anchorEl={childNode}
        open={open}
        placement={placement}
        transition
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <Arrow id="arrow" ref={setArrowRef} />
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <Box padding={2}>{content}</Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </StyledPopper>
    </div>
  );
}

export default RichTooltip;
