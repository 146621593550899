import React, { useState } from 'react';
import BaseDialog from './BaseDialog';
import PrimaryButton from './PrimaryButton';

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  dialogName?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  buttonComponent?: React.ElementType;
  children?: React.ReactNode;
  disableSubmit?: boolean;
  submitTip?: string;
  [key: string]: any; // Allow additional props
}

export default function ConfirmDialog({
  open,
  onClose,
  title,
  dialogName,
  maxWidth = 'sm',
  onConfirm = () => { },
  onCancel = () => { },
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  buttonComponent = PrimaryButton,
  children,
  disableSubmit: disableSubmitProps = false,
  hideCloseButton: hideCancelButton = false,
  submitTip = '',
  ...rest
}: ConfirmDialogProps) {
  const [disableSubmit, setDisableSubmit] = useState(false);

  let actions = {
    [confirmText]: async () => {
      setDisableSubmit(true);
      await onConfirm();
      setDisableSubmit(false);
    }
  }

  if (!hideCancelButton) {
    actions = {
      ...actions,
      [cancelText]: () => {
        onCancel();
        onClose();
      }
    }
  }

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      title={title}
      actionButtonComponent={buttonComponent}
      dialogName={dialogName}
      actions={actions}
      disableSubmit={disableSubmit || disableSubmitProps}
      submitTip={submitTip}
      {...rest}
    >
      {children}
    </BaseDialog>
  );
}
