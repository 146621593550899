import React, { useState, useEffect, ChangeEvent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ConfirmDialog from './ConfirmDialog';
import OutlinedTextField from './fields/OutlinedTextField';
import PercentField from './fields/PercentField';
import UsdTextField from './fields/UsdTextField';
import constants from './constants';
import { useEobData, useFetchPlans } from './utilities/PlanConfigurationUtils';
import axios from './axios';
import filterEmpty from './utilities/filterEmpty';
import { notify } from './Notification';

interface PlanValues {
  id: number;
  plan_label?: string;
  template_name?: string;
  plan_template_name?: string;
  annual_max?: number;
  family_annual_max?: number;
  child_ortho_max?: number;
  adult_ortho_max?: number;
  in_pds_covered?: number;
  in_brs_covered?: number;
  in_mrs_covered?: number;
  oon_pds_covered?: number;
  oon_brs_covered?: number;
  oon_mrs_covered?: number;
  deductible?: number;
  deductible_family_cap?: number;
  deductible_oon_only?: boolean;
  oon_scheme?: string;
  ucr_percentile?: number;
  charge_check_fee?: boolean;
  all_waiting_periods?: number;
  pds_waiting_period?: number;
  brs_waiting_period?: number;
  mrs_waiting_period?: number;
  ortho_waiting_period?: number;
  custom_annual_max_default?: string;
  custom_annual_max_error?: string;
  family_annual_max_default?: string;
  family_annual_max_error?: string;
  custom_ortho_default?: string;
  custom_ortho_error?: string;
  custom_adult_ortho_default?: string;
  custom_adult_ortho_error?: string;
  can_update_plan?: boolean;
  cannot_update_plan_reason?: string;
  can_delete_plan?: boolean;
  cannot_delete_plan_reason?: string;
}

interface StandardPlanListConfigurationDialogProps {
  open: boolean;
  planId: number;
  planExtraId: number;
  planTemplateName: string;
  planYear: number;
  extendedStandardPlanValues: any[];
  handleChange: (planId: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  resetFields: (planId: number, plan: any) => void;
  canChange: boolean;
  employerId?: string;
  portal: string;
}

const useStyles = makeStyles({
  dollar: {
    marginRight: '0',
  },
  checkFee: {
    marginTop: 28,
  },
});

const isValidPlan = (plan: any) => {
  const hasUcrPercentage = plan.oon_scheme === 'ucr' ? plan.ucr_percentile : true;
  return plan.oon_scheme && hasUcrPercentage && plan.plan_label;
};

function StandardPlanListConfigurationDialog({
  open,
  planId,
  planExtraId,
  planTemplateName,
  planYear,
  extendedStandardPlanValues,
  handleChange: handleStandardPlanChange,
  onClose,
  resetFields,
  employerId,
  portal,
}: StandardPlanListConfigurationDialogProps) {
  const classes = useStyles();
  const [templateDefaults, setTemplateDefaults] = useState();
  const [planValues, setPlanValues] = useState<PlanValues>({});
  const [existingPlanInitialValues, setExistingPlanInitialValues] = useState<PlanValues>({});
  const { eobData } = useEobData(portal);

  const {
    refreshPlans,
  } = useFetchPlans({
    portal,
    employerId,
    planYearId: planYear,
  });

  useEffect(() => {
    if (planTemplateName && planYear) {
      eobData
        .mutateAsync({ planTemplateName, planYear })
        .then((result) => {
          setTemplateDefaults(result);
        });
    }
  }, [planTemplateName, planYear]);

  useEffect(() => {
    if (!extendedStandardPlanValues || !planId) {
      setPlanValues({});
      return;
    }
    if (planExtraId && templateDefaults) {
      let editedPlan =
        extendedStandardPlanValues.find(({ extra_id }) => extra_id === planExtraId) || {};
      if (!isValidPlan(editedPlan)) {
        editedPlan = {
          ...editedPlan,
          ...templateDefaults,
        };
      }
      setPlanValues(editedPlan);
      return;
    }
    const initialPlanValues = extendedStandardPlanValues.find(({ id }) => id === planId) || {};
    if (!planExtraId && !existingPlanInitialValues?.id && initialPlanValues) {
      setExistingPlanInitialValues(initialPlanValues);
    }
    setPlanValues(initialPlanValues);
  }, [extendedStandardPlanValues, planId, planExtraId, templateDefaults]);

  if (!planId && !planExtraId) {
    return null;
  }

  const handleNewStandardPlanChange =
    (planId: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked, type } = event.target;
      const keyName = name || value;
      const keyValue = type === 'checkbox' ? checked : value;
      setPlanValues({ ...planValues, [keyName]: keyValue });
    };

  const handleChange = planExtraId ? handleNewStandardPlanChange : handleStandardPlanChange;

  const planLabel = planValues.plan_label || planValues.template_name || planValues.plan_template_name;

  const canChange = planExtraId || planValues.can_update_plan;

  const onCancel = () => {
    if (canChange) {
      if (!planExtraId) {
        resetFields(planValues.id, existingPlanInitialValues);
      }
      setTemplateDefaults(undefined);
    }
    setExistingPlanInitialValues({});
    setPlanValues({});
    onClose();
  };

  const createPlan = (plan: PlanValues) => {
    const url = portal === constants.PORTALS.ADMIN
      ? `mast-alto/standard-plan/create-plan/?corporation=${employerId}`
      : 'corp/standard-plan/create-plan/';

    const payload = {
      plan_year: planYear,
      plan_data: { 
        ...plan,
        waiting_periods: filterEmpty({
          All: plan.all_waiting_periods,
          BRS: plan.brs_waiting_period,
          PDS: plan.pds_waiting_period,
          MRS: plan.mrs_waiting_period,
          Ortho: plan.ortho_waiting_period,
        }),
      },
    };

    axios
      .post(url, payload)
      .then(() => {
        refreshPlans();
      })
      .catch((error: any) => {
        notify(error.message, 'error');
      });
  }

  const updatePlan = (plan: PlanValues) => {
    const url = portal === constants.PORTALS.ADMIN
      ? `mast-alto/standard-plan/${plan.id}/update-plan/?corporation=${employerId}`
      : `corp/standard-plan/${plan.id}/update-plan/`;

    const payload = {
      plan_year: planYear,
      plan_data: { 
        ...plan,
        waiting_periods: filterEmpty({
          All: plan.all_waiting_periods,
          BRS: plan.brs_waiting_period,
          PDS: plan.pds_waiting_period,
          MRS: plan.mrs_waiting_period,
          Ortho: plan.ortho_waiting_period,
        }),
      },
    };

    axios
      .patch(url, payload)
      .then(() => {
        refreshPlans();
      })
      .catch((error: any) => {
        notify(error.message, 'error');
      });
  }

  const onSave = () => {
    if (planExtraId) {
      createPlan(planValues);
      setExistingPlanInitialValues({});
    } else {
      updatePlan(planValues);
      setExistingPlanInitialValues(planValues);
    }
    setPlanValues({});
    onClose();
  };

  const shouldDisplayOrthoFields =
    [
      constants.STANDARD_PLAN_PLATINUM_PLUS,
      constants.STANDARD_PLAN_GOLD_PLUS,
      constants.STANDARD_PLAN_SILVER_PLUS,
    ].includes(planValues.plan_template_name || '') ||
    ![
      constants.STANDARD_PLAN_PLATINUM_STANDARD,
      constants.STANDARD_PLAN_PLATINUM_ESSENTIAL,
      constants.STANDARD_PLAN_GOLD_STANDARD,
      constants.STANDARD_PLAN_GOLD_ESSENTIAL,
      constants.STANDARD_PLAN_SILVER_STANDARD,
      constants.STANDARD_PLAN_SILVER_ESSENTIAL,
    ].includes(planValues.plan_template_name || '');

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      dialogName="standard-plan-config-dialog"
      fullWidth={false}
      maxWidth="lg"
      title={`Configure ${planLabel} Plan`}
      disableSubmit={!canChange || !isValidPlan(planValues)}
      submitTip={planValues.cannot_update_plan_reason}
      confirmText="Save"
      onConfirm={onSave}
      onCancel={onCancel}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">Plan Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <OutlinedTextField
            id="plan_label"
            name="plan_label"
            label="Plan Name"
            onChange={handleChange(planId)}
            value={planValues.plan_label || ''}
            InputLabelProps={{ shrink: true }}
            disabled={!canChange}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">Annual Max</Typography>
        </Grid>
        <Grid item xs={3}>
          <OutlinedTextField
            id="standard-plan-custom-annual-max"
            name="annual_max"
            label="Annual Max"
            onChange={handleChange(planId)}
            value={planValues.annual_max || ''}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.dollar} position="start" disableTypography>
                  $
                </InputAdornment>
              ),
            }}
            placeholder={planValues.custom_annual_max_default}
            helperText={planValues.custom_annual_max_error}
            error={!!planValues.custom_annual_max_error}
            disabled={!canChange || !planValues.allow_annual_max_override}
          />
        </Grid>
        <Grid item xs={3}>
          <OutlinedTextField
            id="standard-plan-family-annual-max"
            name="family_annual_max"
            label="Family Annual Max"
            onChange={handleChange(planId)}
            value={planValues.family_annual_max || ''}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.dollar} position="start" disableTypography>
                  $
                </InputAdornment>
              ),
            }}
            placeholder={planValues.family_annual_max_default}
            helperText={planValues.family_annual_max_error}
            error={!!planValues.family_annual_max_error}
            disabled={!canChange || !planValues.allow_annual_max_override}
          />
        </Grid>
        {shouldDisplayOrthoFields && (
          <>
            <Grid item xs={3}>
              <OutlinedTextField
                id="standard-plan-custom-annual-ortho-max"
                name="child_ortho_max"
                label="Annual Child Ortho Max"
                onChange={handleChange(planId)}
                value={planValues.child_ortho_max || ''}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={classes.dollar} position="start" disableTypography>
                      $
                    </InputAdornment>
                  ),
                }}
                placeholder={planValues.custom_ortho_default}
                helperText={planValues.custom_ortho_error}
                error={!!planValues.custom_ortho_error}
                disabled={!canChange || !planValues.allow_ortho_max_override}
              />
            </Grid>
            <Grid item xs={3}>
              <OutlinedTextField
                id="standard-plan-custom-annual-adult-ortho-max"
                name="adult_ortho_max"
                label="Annual Adult Ortho Max"
                onChange={handleChange(planId)}
                value={planValues.adult_ortho_max || ''}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={classes.dollar} position="start" disableTypography>
                      $
                    </InputAdornment>
                  ),
                }}
                placeholder={planValues.custom_adult_ortho_default}
                helperText={planValues.custom_adult_ortho_error}
                error={!!planValues.custom_adult_ortho_error}
                disabled={!canChange || !planValues.allow_ortho_max_override}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant="h3">Coverage</Typography>
        </Grid>
        <Grid item xs={4}>
          <PercentField
            id="in_pds_covered"
            label="PDS % Covered In-Network"
            name="in_pds_covered"
            value={planValues.in_pds_covered || ''}
            onChange={handleChange(planId)}
            disabled={!canChange}
          />
        </Grid>
        <Grid item xs={4}>
          <PercentField
            id="in_brs_covered"
            label="BRS % Covered In-Network"
            name="in_brs_covered"
            value={planValues.in_brs_covered || ''}
            onChange={handleChange(planId)}
            disabled={!canChange}
          />
        </Grid>
        <Grid item xs={4}>
          <PercentField
            id="in_mrs_covered"
            label="MRS % Covered In-Network"
            name="in_mrs_covered"
            value={planValues.in_mrs_covered || ''}
            onChange={handleChange(planId)}
            disabled={!canChange}
          />
        </Grid>
        <Grid item xs={4}>
          <PercentField
            id="oon_pds_covered"
            label="PDS % Covered Out of Network"
            name="oon_pds_covered"
            value={planValues.oon_pds_covered || ''}
            onChange={handleChange(planId)}
            disabled={!canChange}
          />
        </Grid>
        <Grid item xs={4}>
          <PercentField
            id="oon_brs_covered"
            label="BRS % Covered Out of Network"
            name="oon_brs_covered"
            value={planValues.oon_brs_covered || ''}
            onChange={handleChange(planId)}
            disabled={!canChange}
          />
        </Grid>
        <Grid item xs={4}>
          <PercentField
            id="oon_mrs_covered"
            label="MRS % Covered Out of Network"
            name="oon_mrs_covered"
            value={planValues.oon_mrs_covered || ''}
            onChange={handleChange(planId)}
            disabled={!canChange}
          />
        </Grid>
        <Grid item xs={6} container>
          <Grid item xs={12}>
            <Typography variant="h3">Deductible</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="deductible-oon-only"
                  name="deductible_oon_only"
                  checked={planValues.deductible_oon_only}
                  onChange={handleChange(planId)}
                  disabled={!canChange}
                />
              }
              label="Apply deductible for out-of-network visits only"
              disabled={!canChange}
            />
          </Grid>
          <Grid item xs={6}>
            <UsdTextField
              id="deductible"
              label="Deductible"
              name="deductible"
              value={planValues.deductible || ''}
              onChange={handleChange(planId)}
              disabled={!canChange}
            />
          </Grid>
          <Grid item xs={6}>
            <UsdTextField
              id="deductible-family-cap"
              label="Deductible Family Cap"
              name="deductible_family_cap"
              value={planValues.deductible_family_cap || ''}
              onChange={handleChange(planId)}
              disabled={!canChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Out-of-network Pricing</Typography>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              id="oon-scheme"
              name="oon_scheme"
              select
              label="OON Scheme"
              value={planValues.oon_scheme || ''}
              onChange={handleChange(planId)}
              disabled={!canChange}
            >
              <MenuItem value="ucr">UCR</MenuItem>
              <MenuItem value="max_allowed">MAC</MenuItem>
            </OutlinedTextField>
          </Grid>
          <Grid item xs={6}>
            <OutlinedTextField
              id="ucr-percentile"
              name="ucr_percentile"
              select
              label="UCR %"
              value={planValues.ucr_percentile || ''}
              onChange={handleChange(planId)}
              disabled={!canChange || planValues.oon_scheme === 'max_allowed'}
            >
              {[50, 60, 70, 75, 80, 85, 90, 95].map((percentage) => (
                <MenuItem key={`${percentage}`} value={percentage}>
                  {percentage}%
                </MenuItem>
              ))}
            </OutlinedTextField>
          </Grid>
          {portal === constants.PORTALS.ADMIN && (
            <Grid item xs={12} className={classes.checkFee}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="charge-check-fee"
                    name="charge_check_fee"
                    checked={planValues.charge_check_fee}
                    onChange={handleChange(planId)}
                  />
                }
                label="Mailed paper claim processing fee"
                disabled={!canChange}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} container>
          <Grid item xs={12}>
            <Typography variant="h3">Waiting Periods In Days</Typography>
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              id="all-waiting-periods"
              name="all_waiting_periods"
              label="All Services"
              value={planValues.all_waiting_periods || ''}
              onChange={handleChange(planId)}
              type="number"
              inputProps={{
                max: 120,
              }}
              disabled={!canChange}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              id="pds-waiting-period"
              name="pds_waiting_period"
              label="PDS"
              value={planValues.pds_waiting_period || ''}
              onChange={handleChange(planId)}
              type="number"
              inputProps={{
                max: 120,
              }}
              disabled={!canChange}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              id="brs-waiting-period"
              name="brs_waiting_period"
              label="BRS"
              value={planValues.brs_waiting_period || ''}
              onChange={handleChange(planId)}
              type="number"
              inputProps={{
                max: 120,
              }}
              disabled={!canChange}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              id="mrs-waiting-period"
              name="mrs_waiting_period"
              label="MRS"
              value={planValues.mrs_waiting_period || ''}
              onChange={handleChange(planId)}
              tip={
                !!planValues.all_waiting_periods &&
                'Waiting period for all services has already been specified'
              }
              type="number"
              inputProps={{
                max: 120,
              }}
              disabled={!canChange}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              id="ortho-waiting-period"
              name="ortho_waiting_period"
              label="Ortho Services"
              value={planValues.ortho_waiting_period || ''}
              onChange={handleChange(planId)}
              type="number"
              inputProps={{
                max: 120,
              }}
              disabled={!canChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
}

export default StandardPlanListConfigurationDialog;
