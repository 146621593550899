import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatDateTimeToUtcLocal = (utcDate: string) =>
  dayjs.utc(utcDate).local().format('MMM D, YYYY hh:mm A');

export default formatDateTimeToUtcLocal;
