import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import bentoErrorPage from '../public/bento_error_bg.png';
import { bentoTeal3, pureWhite } from './colors';

interface ErrorPageProps {
  resetError: () => void;
  error?: Error;
}

const Overlay = styled(Box)({
  color: pureWhite,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'grid',
  placeItems: 'center',
});

const Background = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  backgroundImage: `url(${bentoErrorPage})`,
  borderTop: `${theme.spacing(2)} solid ${bentoTeal3}`,
  borderBottom: `${theme.spacing(2)} solid ${bentoTeal3}`,
}));

function ErrorPage({ resetError, error }: ErrorPageProps) {
  const history = useHistory();

  // detect chunk loading error (new version issue)
  const isChunkError = error?.message?.toLowerCase().includes('chunk');

  return (
    <Background>
      <Overlay height="75%" width="100%">
        <Stack alignItems="center">
          {!isChunkError && (
            <Typography variant="h1" mt={0}>
              Sorry!
            </Typography>
          )}
          <Box textAlign="center">
            <Typography variant="h2">
              {isChunkError
                ? 'The Bento portal has been updated, please refresh your browser tab to get the latest changes.'
                : 'There’s a problem on our end.'}
            </Typography>
          </Box>
          {!isChunkError && (
            <Typography py={1}>If the issue persists, contact Bento at smile@bento.net.</Typography>
          )}
          <Button
            color="primary"
            variant="outlined"
            sx={{
              borderRadius: 25,
              border: 'none !important',
              backgroundColor: `${pureWhite} !important`,
              mt: 2,
            }}
            onClick={(e) => {
              e.preventDefault();
              if (isChunkError) {
                window.location.reload();
              } else {
                resetError();
                window.location.href = '/';
                history.push('/');
              }
            }}
          >
            {isChunkError ? 'Refresh' : 'Back to the home page'}
          </Button>
        </Stack>
      </Overlay>
    </Background>
  );
}

export default ErrorPage;
