import React, { useState, useEffect, lazy, Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  axios,
  constants,
  Notification,
  baseTheme,
  AppLayout,
  PrivateRoute,
  PublicRoute,
  QueryCacheProvider,
  UpdatePassword,
  Loader,
  ErrorBoundary,
  NotFoundPage,
} from '@bento-dental/core';
import { StorageProvider, useInitializer, useLoginHandler } from '@bento-dental/core/hooks';
import SignIn from './components/Account/SignIn';

const ManageOrtho = lazy(() => import('./components/Ortho/ManageOrtho'));
const ManagePlan = lazy(() => import('./components/Ortho/ManagePlan'));
const StartPaymentPlan = lazy(() => import('./components/Ortho/StartPaymentPlan'));
const CreateReimbursement = lazy(() => import('./components/Reimbursement/CreateReimbursement'));
const Reimbursement = lazy(() => import('./components/Reimbursement/Reimbursement'));
const ReimbursementDetails = lazy(() => import('./components/Reimbursement/ReimbursementDetails'));
const EditInOfficePlan = lazy(() => import('./components/InOfficePlans/EditInOfficePlan'));
const MemberDetails = lazy(() => import('./components/Members/MemberDetails'));
const ManageInOfficePlan = lazy(() => import('./components/InOfficePlans/ManageInOfficePlan'));
const PatientEligibility = lazy(() => import('./components/Members/PatientEligibility'));
const AdminUsers = lazy(() => import('./components/Users/AdminUsers'));
const AllUsers = lazy(() => import('./components/Users/AllUsers'));
const Claims = lazy(() => import('./components/Claims/Claims'));
const Members = lazy(() => import('./components/Members/Members'));
const HeaderView = lazy(() => import('./components/Header/HeaderView'));
const Practices = lazy(() => import('./components/Practices/Practices'));
const CandidatePractices = lazy(() => import('./components/CandidatePractices/CandidatePractices'));
const Employers = lazy(() => import('./components/Employers/Employers'));
const InOfficePlans = lazy(() => import('./components/InOfficePlans/InOfficePlans'));
const SignUp = lazy(() => import('./components/Account/SignUp'));
const Debug = lazy(() => import('./components/Account/Debug'));
const CompleteEmailChange = lazy(() => import('./components/Account/CompleteEmailChange'));
const PracticeDetailView = lazy(() => import('./components/Practices/PracticeDetailView'));
const EmployerDetails = lazy(() => import('./components/Employers/EmployerDetails'));
const ClaimsEditView = lazy(() => import('./components/Claims/ClaimsEditView'));
const CustomizedPlans = lazy(() => import('./components/CustomizedPlans/CustomizedPlans'));
const CustomizedPlanTabView = lazy(
  () => import('./components/CustomizedPlans/CustomizedPlanTabView')
);
const ClaimInvoices = lazy(() => import('./components/ClaimInvoices/ClaimInvoices'));
const Workflows = lazy(() => import('./components/Claims/Workflows'));
const DashboardAdmin = lazy(() => import('./components/Dashboard/DashboardAdmin'));
const Assistant = lazy(() => import('./components/Assistant/Assistant'));

const theme = createTheme(baseTheme);

const getPlanLinks = (role) => {
  let links = null;
  switch (role) {
    case 'admin_admin':
      links = [
        { title: 'In-Office plans', id: 'inop-plans', route: '/plans', item: [] },
        { title: 'Customized plans', route: '/customized-plans', id: 'customizedplan' },
        { title: 'Dashboard', route: '/dashboard', id: 'dashboard' },
      ];
      break;
    case 'admin_cs':
    case 'admin_csm':
    case 'admin_csm_admin':
      links = [
        { title: 'In-Office plans', id: 'inop-plans', route: '/plans', item: [] },
        { title: 'Dashboard', route: '/dashboard', id: 'dashboard' },
      ];
      break;
    default:
      links = [];
  }
  return links;
};

const getNavLinks = (role) => [
  { title: 'Search for members', id: 'members', route: ['/members', '/'] },
  { title: 'Practices', id: 'practices', route: '/practices' },
  { title: 'Candidate practices', id: 'candidates', route: '/candidates' },
  { title: 'Employers', id: 'employers', route: '/employers' },
  { title: 'Reimbursements', id: 'reimbursements', route: '/reimbursements' },
  {
    title: 'Plans',
    id: 'plans',
    route: '/plans',
    items: getPlanLinks(role),
  },
  { title: 'Admin users', id: 'admin-users', route: '/admin-users' },
  { title: 'Portal users', id: 'all-users', route: '/all-users' },
  {
    title: 'Claims',
    id: 'claims',
    route: '/claims',
    items: [{ title: 'Workflows', route: '/claim-workflows' }],
  },
  {
    title: 'Claims invoices',
    id: 'claims-invoices',
    route: '/invoices',
  },
];

function App() {
  const [shouldRender, setShouldRender] = useState(false);
  const [debugOpen, setDebugOpen] = useState(false);
  const { isLoggedIn, login } = useLoginHandler();
  const [currentUser, setCurrentUser] = useState({});

  const { environment, version, debug, serverDateTime, reinitialize } = useInitializer({
    portal: constants.PORTALS.ADMIN,
    onAfterInit() {
      setShouldRender(true);
    },
  });

  function checkUserSignUp() {
    return axios.get('/mast-alto/check-user-signup/').then((response) => {
      setCurrentUser(response.data.data);
    });
  }

  useEffect(() => {
    if (isLoggedIn) {
      checkUserSignUp();
    }
  }, [isLoggedIn]);

  const { role, username, user_id: userId } = currentUser;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryCacheProvider>
            <StorageProvider value={{ username, role, environment, reinitialize }}>
              <Suspense fallback={<Loader />}>
                {shouldRender && (
                  <ErrorBoundary>
                    <HashRouter>
                      <AppLayout
                        links={getNavLinks(role)}
                        envProps={{
                          debug,
                          version,
                          environment,
                          serverDateTime,
                          onClick() {
                            setDebugOpen(true);
                          },
                        }}
                        header={<HeaderView />}
                        hideLayout={!isLoggedIn}
                      >
                        <Suspense fallback={<Loader />}>
                          <Switch>
                            <PrivateRoute path="/members/:memberId" component={MemberDetails} />
                            <PrivateRoute path="/members" component={Members} />
                            <PrivateRoute path="/admin-users" component={AdminUsers} />
                            <PrivateRoute path="/all-users" component={AllUsers} />
                            <PrivateRoute path="/claims-edit/:claimId" component={ClaimsEditView} />
                            <PrivateRoute path="/claims" component={Claims} />
                            <PrivateRoute path="/claim-workflows" component={Workflows} />
                            <PrivateRoute
                              path="/practices/:practiceId"
                              component={PracticeDetailView}
                            />
                            <PrivateRoute path="/practices" component={Practices} />
                            <PrivateRoute path="/candidates" component={CandidatePractices} />
                            <PrivateRoute
                              path="/employers/:employerId"
                              component={EmployerDetails}
                            />
                            <PrivateRoute path="/employers" component={Employers} />
                            <PrivateRoute
                              path="/reimbursements/:reimbursementId"
                              component={ReimbursementDetails}
                            />
                            <PrivateRoute path="/reimbursements" component={Reimbursement} />
                            <PrivateRoute path="/plans" component={InOfficePlans} />
                            <PublicRoute path={['/sign-up', '/signup']} component={SignUp} />
                            <Route path="/complete-email-change" component={CompleteEmailChange} />
                            <PublicRoute path="/update-password">
                              <UpdatePassword portal={constants.PORTALS.ADMIN} />
                            </PublicRoute>
                            <PublicRoute path={['/sign-in', '/signin']}>
                              <SignIn login={login} />
                            </PublicRoute>
                            <PrivateRoute
                              path="/manage-ortho-plan/:planId"
                              component={ManagePlan}
                            />
                            <PrivateRoute
                              path="/manage-ortho/:memberId/start-plan"
                              component={StartPaymentPlan}
                            />
                            <PrivateRoute path="/manage-ortho/:memberId" component={ManageOrtho} />
                            <PrivateRoute
                              path="/create-reimbursement/:memberId"
                              component={CreateReimbursement}
                            />
                            <PrivateRoute
                              path="/patient-eligibility/:memberId"
                              component={PatientEligibility}
                            />
                            <PrivateRoute
                              path="/in-office-plans/:inOfficePlanId/edit"
                              component={EditInOfficePlan}
                            />
                            <PrivateRoute
                              path="/manage-in-office-plan/:inOfficePlanId"
                              component={ManageInOfficePlan}
                            />
                            <PrivateRoute path="/customized-plans" component={CustomizedPlans} />
                            <PrivateRoute
                              path="/CustomizedPlanView/:name"
                              component={CustomizedPlanTabView}
                            />
                            <PrivateRoute path="/invoices" component={ClaimInvoices} />
                            <PrivateRoute exact path="/" component={Members} />
                            <PrivateRoute path="/dashboard" component={DashboardAdmin} />
                            <Route component={NotFoundPage} />
                          </Switch>
                        </Suspense>
                        <Debug
                          isOpen={debugOpen}
                          handleClose={() => {
                            setDebugOpen(false);
                          }}
                        />
                        {isLoggedIn && <Assistant />}
                      </AppLayout>
                    </HashRouter>
                  </ErrorBoundary>
                )}
              </Suspense>
              <Notification />
            </StorageProvider>
          </QueryCacheProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
}

export default App;
