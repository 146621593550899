import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import axios from './axios';
import BentoLogo from './BentoLogo';
import { notify } from './Notification';
import constants from './constants';
import { validateEmail } from './utilities';
import mixpanelLogger from './mixpanelLogger';
import OutlinedTextField from './fields/OutlinedTextField';
import PrimaryButton from './PrimaryButton';

const portalNames = {
  [constants.PORTALS.ADMIN]: 'Admin Portal',
  [constants.PORTALS.DENTIST]: 'Dentist Portal',
  [constants.PORTALS.EMPLOYER]: 'Employer Portal',
  [constants.PORTALS.MEMBER]: 'Member Portal',
};

export default function ForgotUsername({ portal }: { portal: string }) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const submitEmail = useMutation(
    (email: string) => axios.post(`/docs/forgot-username/`, { office_email: email }),
    {
      onSuccess: ({ data }) => {
        notify(data.data, 'success');
        history.push('/');
      },
      onError: (error) => {
        notify((error as Error).message, 'error');
      },
    }
  );

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    setDisableButton(true);
    if (!validateEmail(email)) {
      setEmailError('Invalid email address. Please correct it before submitting.');
      return;
    }
    submitEmail.mutateAsync(email);
    mixpanelLogger.track('Forgot Username: Forgot Username submit button clicked', { email });
  };

  return (
    <Box>
      <Fade in>
        <Paper sx={{ padding: 6, width: 500, margin: '15% auto' }}>
          <Stack component="form" autoComplete="off" noValidate>
            <Box mb={2}>
              <BentoLogo portalText={portalNames[portal]} />
            </Box>
            <Typography variant="h2">Forgot Username</Typography>
            <Typography variant="body2">
              Enter your office email address. This should be the email address you used to signup
              with Bento.
            </Typography>
            <OutlinedTextField
              id="email"
              type="email"
              name="email"
              label="Email"
              placeholder="Enter email"
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
            />
            <PrimaryButton
              disabled={disableButton || !!emailError}
              id="submit-office-email-username-recovery"
              onClick={handleSubmit}
            >
              Send Email
            </PrimaryButton>
          </Stack>
        </Paper>
      </Fade>
    </Box>
  );
}
