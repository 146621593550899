import { Dayjs } from 'dayjs';
import formatDate from './formatDate';

/**
 * Formats a date into the `YYYY-MM-DD` format for API requests.
 *
 * @param date - The date to format, as a string or Date object.
 * @returns The formatted date string, or `null` if the date is invalid.
 */
const formatDateForRequest = (date: string | Date | null | undefined | Dayjs): string | null => {
  return formatDate(date, 'YYYY-MM-DD', undefined);
};

export default formatDateForRequest;
