import React from 'react';
import { Stack, Typography, styled } from '@mui/material';
import { pureWhite, bentoGreen3 } from './colors';
import formatDateTime from './utilities/formatDateTime';

export interface EnvironmentBannerProps {
  environment: string;
  version: string;
  serverDateTime: string | Date;
  onClick?: () => void;
}

// Utility function to determine environment color
const getEnvironmentColor = (env: string, theme: any): string => {
  if (env.includes('dev')) {
    return theme.palette.primary.main;
  }
  if (env === 'qa' || env === 'test') {
    return theme.palette.secondary.light;
  }
  return bentoGreen3;
};

// Styled components
const Banner = styled(Stack)({
  gap: 0,
  fontSize: '1.15rem',
  bottom: 0,
  color: pureWhite,
  width: 'inherit',
  padding: 5,
  position: 'fixed',
  textAlign: 'center',
});

const BannerText = styled(Typography)({
  fontWeight: 600,
  fontSize: 'inherit',
  textTransform: 'uppercase',
});

function EnvironmentBanner({
  environment,
  version,
  serverDateTime,
  onClick,
}: EnvironmentBannerProps) {
  if (!environment || environment === 'prod') {
    return null;
  }

  return (
    <Banner
      sx={{
        backgroundColor: (theme: string) => getEnvironmentColor(environment, theme),
        cursor: onClick ? 'pointer' : 'inherit',
      }}
      onClick={onClick || (() => {})}
      aria-hidden="true"
    >
      <BannerText>{environment}</BannerText>
      <BannerText>{formatDateTime(serverDateTime, 'YYYY-MM-DD HH:mm:ss', true)}</BannerText>
      {(environment === 'qa' || environment === 'test' || environment === 'demo') && (
        <BannerText>({version})</BannerText>
      )}
    </Banner>
  );
}

export default EnvironmentBanner;
