import React, { useMemo, ReactNode, JSX } from 'react';
import { StorageContext } from './useStorage';

interface StorageProviderProps {
  value?: Record<string, any>;
  children: ReactNode;
}

export function StorageProvider({ value, children }: StorageProviderProps): JSX.Element {
  const contextValue = useMemo(() => {
    if (value) {
      return new Map<string, any>(Object.entries(value));
    }
    return new Map<string, any>();
  }, [value]);

  return <StorageContext.Provider value={contextValue}>{children}</StorageContext.Provider>;
}

export default StorageProvider;
