/**
 * Checks if an object is empty (i.e., has no enumerable properties).
 *
 * @param object - The object to check.
 * @returns `true` if the object is empty, `false` otherwise.
 */
const isEmpty = (object: Record<string, any> | null | undefined): boolean => {
  return object?.constructor === Object && Object.keys(object).length === 0;
};

export default isEmpty;
