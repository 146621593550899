import React, { ReactNode } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { bentoGreen2 } from './colors';

/*
   Simple wrapper for Typography component to prevent having to declare
   the same properties everywhere and allow some customization.
*/

const useStyles = makeStyles({
  success: {
    color: bentoGreen2,
  },
});

interface TypographySuccessProps extends TypographyProps {
  children: ReactNode;
}

function TypographySuccess({ children, ...other }: TypographySuccessProps): JSX.Element {
  const classes = useStyles();
  return (
    <Typography variant="subtitle1" {...other} className={classes.success}>
      {children}
    </Typography>
  );
}

export default TypographySuccess;
