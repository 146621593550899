import React, { ReactNode, isValidElement } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Loader from './Loader';

const useStyles = makeStyles({
  semibold: {
    fontWeight: 600,
  },
});

type StandardTableProps = {
  id: string;
  headers: ReactNode[];
  rows: ReactNode[][];
  className?: string;
  loading?: boolean;
  noDataMessage?: string;
  noDataColor?: 'error' | 'primary' | 'secondary' | 'default';
  wrapperProps?: {
    className?: string;
    sx?: Record<string, any>;
    [key: string]: any;
  };
  tableProps?: {
    stickyHeader?: boolean;
    [key: string]: any;
  };
  extraScrollWidth?: string;
  headerClasses?: string | string[];
  bodyClasses?: string | string[];
  scroll?: boolean;
};

const getClassnamesString = (classnames: string | string[]) => {
  if (Array.isArray(classnames)) {
    return classnames.join(' ');
  }
  return classnames;
};

export default function StandardTable({
  className = '',
  id,
  headers,
  rows,
  loading = false,
  noDataMessage = 'No results found',
  noDataColor = 'error',
  wrapperProps = {},
  tableProps = {},
  extraScrollWidth = '',
  headerClasses = '',
  bodyClasses = '',
  scroll = false,
}: StandardTableProps) {
  const classes = useStyles();

  const {
    className: wrapperClassName = '',
    sx: wrapperSx = {},
    ...otherWrapperProps
  } = wrapperProps;
  const { stickyHeader } = tableProps;

  const headerClass = getClassnamesString(headerClasses);
  const bodyClass = getClassnamesString(bodyClasses);
  const cellClasses = {
    head: [classes.semibold, headerClass].join(' '),
    body: bodyClass,
  };

  return (
    <Box sx={{ overflow: 'auto' }}>
      <TableContainer
        sx={{
          width: extraScrollWidth || '100%',
          overflow: stickyHeader || scroll || extraScrollWidth ? 'auto' : 'hidden',
          ...wrapperSx,
        }}
        className={wrapperClassName}
        {...otherWrapperProps}
      >
        <Table className={className} aria-label={`${id}-table`} {...tableProps}>
          <TableHead id={`${id}-tablehead`}>
            <TableRow className={headerClass}>
              {headers.map((headerCell, index) =>
                React.cloneElement(headerCell as React.ReactElement, {
                  classes: cellClasses,
                  key: `${id}-header-${index}`,
                })
              )}
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody id={`${id}-tablebody`} className={bodyClass}>
              {rows.map((row, rowIndex) => (
                <TableRow key={`${id}-row-${rowIndex}`} className={bodyClass}>
                  {row.map((rowCell, cellIndex) =>
                    React.cloneElement(rowCell as React.ReactElement, {
                      classes: cellClasses,
                      key: `${id}-row-${rowIndex}-${cellIndex}`,
                    })
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {rows.length === 0 && !loading && (
          <Box mt={1} sx={{ display: 'grid', placeItems: 'center' }}>
            <Typography variant="subtitle1" color={noDataColor}>
              {noDataMessage}
            </Typography>
          </Box>
        )}
        {loading && (
          <Box mt={1} sx={{ display: 'grid', placeItems: 'center' }}>
            <Loader size="sm" />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
}

type HeaderData = string | ReactNode;
type AdjustableColumn = {
  colName: string;
  value: string | number;
};

export function makeHeader(
  id: string,
  headerData: HeaderData[],
  adjustableColumns: AdjustableColumn[] = []
): ReactNode[] {
  return headerData.map((headerDatum, index) => {
    const entry = adjustableColumns.find((e) => e.colName === headerDatum);
    return (
      <TableCell key={`${id}-${index}`} variant="head" width={entry ? entry.value : undefined}>
        {headerDatum}
      </TableCell>
    );
  });
}

type RowData = string | number | ReactNode | { content: ReactNode; [key: string]: any };

export function makeRow(id: string, rowData: RowData[], isLit = false): ReactNode[] {
  return rowData.map((rowDatum, index) => {
    const borderLeft = isLit && index === 0 ? '6px solid #0361A0' : '0px';
    const paddingLeft = isLit && index === 0 ? '13px' : undefined;
    const cellProps = { key: `${id}-${index}`, style: { borderLeft, paddingLeft } };

    if (rowDatum && typeof rowDatum === 'object' && !isValidElement(rowDatum)) {
      const { content, ...options } = rowDatum as { content: ReactNode; [key: string]: any };
      return (
        <TableCell {...cellProps} {...options}>
          {content}
        </TableCell>
      );
    }
    return <TableCell {...cellProps}>{rowDatum}</TableCell>;
  });
}
