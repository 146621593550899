import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import axios from './axios';
import BentoLogo from './BentoLogo';
import Loader from './Loader';
import { notify } from './Notification';
import constants from './constants';

import OutlinedTextField from './fields/OutlinedTextField';

import PrimaryButton from './PrimaryButton';
import mixpanelLogger from './mixpanelLogger';

const portalNames = {
  [constants.PORTALS.ADMIN]: 'Admin Portal',
  [constants.PORTALS.DENTIST]: 'Dentist Portal',
  [constants.PORTALS.EMPLOYER]: 'Employer Portal',
  [constants.PORTALS.MEMBER]: 'Member Portal',
};

interface ForgotPasswordProps {
  portal: string;
}

function ForgotPassword({ portal }: ForgotPasswordProps) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const requestForgottenPassword = () => {
    if (!email) {
      setEmailError(constants.VALIDATION.REQUIRED);
      return;
    }
    setLoader(true);
    let payload = {
      email: email,
      account_type: portal,
    };
    axios
      .post('/password-reset/initiate-forgot/', payload)
      .then((response) => {
        mixpanelLogger.track('forgotPasswordInitiate', payload);
        notify(response.data.message, 'success');
      })
      .catch((error) => {
        notify(error.message, 'error');
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <Box>
      <Paper sx={{ padding: 6, width: 500, margin: '15% auto' }}>
        {loader ? (
          <Loader />
        ) : (
          <Stack component="form" autoComplete="off" noValidate>
            <Box mb={2}>
              <BentoLogo portalText={portalNames[portal]} />
            </Box>
            <Typography variant="h2">Forgot Password</Typography>
            <OutlinedTextField
              id="email"
              name="email"
              label="Email"
              placeholder="Enter Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value.trim());
                setEmailError('');
              }}
              error={!!emailError}
              helperText={emailError}
            />
            <Stack mt={2} direction="row" justifyContent="space-between">
              <PrimaryButton
                id="reset-password"
                onClick={() => {
                  mixpanelLogger.track('Forgot Password: Forgot Password clicked', { email });
                  requestForgottenPassword();
                }}
              >
                Reset Password
              </PrimaryButton>
              <Button variant="text" onClick={() => history.push('/sign-in')}>
                Back
              </Button>
            </Stack>
          </Stack>
        )}
      </Paper>
    </Box>
  );
}

export default ForgotPassword;
