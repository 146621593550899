import React, { useState, ForwardRefRenderFunction, ChangeEvent } from 'react';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { NumericFormat, PatternFormat } from 'react-number-format';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedTextField from './OutlinedTextField';

interface PasswordFieldProps extends Omit<TextFieldProps, 'type' | 'value'> {
  value: string;
  className?: string;
  length?: number;
  inputProps?: TextFieldProps['InputProps'];
  numericOnly?: boolean;
  format?: string;
  mask?: string;
  maxLength?: number;
  type?: string;
}

// eslint-disable-next-line react/function-component-definition
const PasswordField: ForwardRefRenderFunction<HTMLInputElement, PasswordFieldProps> = (
  {
    value,
    className = '',
    length = 100,
    inputProps = {},
    type = 'password',
    numericOnly = false,
    format,
    mask = '_',
    maxLength,
    onChange,
    ...rest
  },
  ref
) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const visibilityAdornment = (
    <InputAdornment position="end">
      <IconButton
        size="large"
        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        aria-label="toggle password visibility"
      >
        {!isPasswordVisible ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  if (numericOnly) {
    return (
      <NumericFormat
        customInput={OutlinedTextField}
        value={value}
        className={className}
        type={isPasswordVisible ? 'text' : 'password'}
        format={format}
        mask={mask}
        allowNegative={false}
        allowLeadingZeros={true}
        isAllowed={(values) => {
          if (!maxLength) return true;
          return values.value.length <= maxLength;
        }}
        InputProps={{
          ...inputProps,
          endAdornment: visibilityAdornment,
        }}
        inputRef={ref}
        onChange={onChange as any}
        {...rest}
      />
    );
  }

  if (format) {
    return (
      <PatternFormat
        customInput={OutlinedTextField}
        value={value}
        className={className}
        type={isPasswordVisible ? 'text' : 'password'}
        format={format}
        mask={mask}
        InputProps={{
          ...inputProps,
          maxLength: length,
          endAdornment: visibilityAdornment,
        }}
        inputRef={ref}
        onChange={onChange as any}
        {...rest}
      />
    );
  }

  return (
    <OutlinedTextField
      value={value}
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        ...inputProps,
        maxLength: length,
        endAdornment: visibilityAdornment,
      }}
      className={className}
      inputRef={ref}
      onChange={onChange}
      {...rest}
    />
  );
};

export default React.forwardRef(PasswordField);
