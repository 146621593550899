import React from 'react';
import { MenuItem, styled } from '@mui/material';
import TextField from '@mui/material/TextField';

type SelectFieldProps = {
  id: string;
  name: string;
  label?: string;
  value?: string | number;
  length?: number;
  options?: Array<{ label: string; value: string | number }>;
  onChange?: (event: React.ChangeEvent<{ value: string }>) => void;
  type?: string;
  disabled?: boolean;
  initialOptionLabel?: string;
  initialOptionValue?: string;
  inputProps?: Record<string, unknown>;
  helperText?: string;
  size?: 'small' | 'medium';
};

const CustomTextField = styled(TextField)(({ disabled }) => ({
  '& .MuiSelect-select.Mui-disabled': {
    '-webkit-text-fill-color': disabled ? '#000' : 'inherit',
    color: disabled ? '#000' : 'inherit',
  },
  '& .MuiSelect-icon': {
    display: disabled ? 'none' : 'block',
  },
}));

// eslint-disable-next-line react/function-component-definition
const SelectField = ({
  id,
  name,
  label,
  value,
  length = 100,
  options,
  onChange,
  helperText,
  inputProps = {},
  type = 'text',
  disabled = false,
  initialOptionLabel,
  initialOptionValue,
  size = 'medium',
}: SelectFieldProps) => (
  <CustomTextField
    id={id}
    fullWidth
    size={size}
    name={name}
    select
    label={label}
    value={value}
    onChange={onChange}
    inputProps={{ ...inputProps, maxLength: length, type }}
    variant="outlined"
    disabled={disabled}
    helperText={helperText}
  >
    {!!initialOptionValue?.length && (
      <MenuItem key={`${id}-initial`} value={initialOptionValue}>
        {initialOptionLabel}
      </MenuItem>
    )}
    {options?.map(({ value, label }) => (
      <MenuItem key={`${id}-${value}`} value={value}>
        {label}
      </MenuItem>
    ))}
  </CustomTextField>
);

export default SelectField;
