import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  lg: {
    margin: '20% auto',
    minHeight: '100px',
  },
  md: {
    margin: '10% auto',
    minHeight: '100px',
  },
  sm: {
    margin: '5% auto',
    minHeight: '100px',
  },
});

interface LoaderProps {
  size?: 'lg' | 'md' | 'sm';
}

function Loader({ size = 'lg' }: LoaderProps) {
  const classes = useStyles();
  return (
    <Grid item container>
      <div className={classes[size]}>
        <CircularProgress size={80} thickness={7} />
      </div>
    </Grid>
  );
}

export default Loader;
