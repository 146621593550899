import React, { ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import ErrorPage from './ErrorPage';
import mixpanelLogger from './mixpanelLogger';

interface ErrorBoundaryProps {
  children: ReactNode;
}

const logErrorToMixpanel = (error: Error, info: { componentStack: string }) => {
  if (mixpanelLogger.hasOwnProperty('get_distinct_id')) {
    mixpanelLogger.track('Error page reached', { error, info });
  }
};

const ErrorFallback = ({ resetErrorBoundary, error }: FallbackProps) => {
  return (
    <ErrorPage
      error={error}
      resetError={() => {
        resetErrorBoundary();
      }}
    />
  );
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={logErrorToMixpanel}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
