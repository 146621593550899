import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tip from './Tip';

const useStyles = makeStyles({
  tooltip: {
    '&:last-child': {
      marginRight: 0,
    },
  },
});

type SecondaryButtonProps = {
  id: string;
  tip?: string;
  children: ReactNode;
  [key: string]: any; // Allow additional props to be passed to the Button component
};

export default function SecondaryButton({ id, tip = '', children, ...rest }: SecondaryButtonProps) {
  const classes = useStyles();

  const button = (
    <Button id={id} variant="contained" color="secondary" {...rest}>
      {children}
    </Button>
  );

  if (tip) {
    return (
      <Tip id={`${id}-tooltip`} className={classes.tooltip} title={tip}>
        {button}
      </Tip>
    );
  }

  return button;
}
