import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OutlinedTextField from './fields/OutlinedTextField';
import PasswordField from './fields/PasswordField';
import { filterEmpty } from './utilities';
import { useFormHandler } from './hooks';
import constants from './constants';
import RichTooltip from './RichTooltip';
import PrimaryButton from './PrimaryButton';

interface UserPasswordFormProps {
  formState: {
    username?: string;
    password: string;
    confirmPassword: string;
  };
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: {
    username?: string;
    password?: string;
    confirmPassword?: string;
  };
  onSubmit: () => void;
  submitLabel: string;
  disabled?: boolean;
  withUsernameField?: boolean;
}

export default function UserPasswordForm({
  formState,
  handleChange,
  errors,
  onSubmit,
  submitLabel,
  disabled = false,
  withUsernameField = false,
}: UserPasswordFormProps) {
  return (
    <Stack component="form" autoComplete="off" noValidate>
      {withUsernameField && (
        <RichTooltip
          content={
            <span>
              <Typography fontWeight={600}>Username requirements</Typography>
              <Typography>Minimum 6 characters</Typography>
              <Typography>No spaces</Typography>
            </span>
          }
        >
          <OutlinedTextField
            id="username"
            autoComplete="username"
            name="username"
            label="Username"
            value={formState.username || ''}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
          />
        </RichTooltip>
      )}
      <RichTooltip
        content={
          <span>
            <Typography fontWeight={600}>Password requirements</Typography>
            <Typography>Minimum 8 characters</Typography>
            <Typography>Include an upper case letter</Typography>
            <Typography>Include a lower case letter</Typography>
            <Typography>Include a number</Typography>
          </span>
        }
      >
        <PasswordField
          id="new-password"
          autoComplete="new-password"
          name="password"
          label="Password"
          type="password"
          placeholder="Enter Password"
          value={formState.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
        />
      </RichTooltip>
      <PasswordField
        id="confirm-password"
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        value={formState.confirmPassword}
        onChange={handleChange}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
      />
      <PrimaryButton id="submit-button" onClick={onSubmit} disabled={disabled}>
        {submitLabel}
      </PrimaryButton>
    </Stack>
  );
}

export const usePasswordForm = (withUsernameField: boolean = false, username: string = '') => {
  const passwordFormState = useMemo(() => {
    return {
      password: '',
      confirmPassword: '',
      username,
    };
  }, [username]);

  const getConfirmPasswordError = (password: string, confirmPassword: string): string | null => {
    if (!confirmPassword) {
      return constants.VALIDATION.REQUIRED;
    }
    if (password !== confirmPassword) {
      return constants.VALIDATION.PASSWORD_MATCH;
    }
    return null;
  };

  const validateForm = (formState: {
    password: string;
    confirmPassword: string;
    username?: string;
  }) =>
    filterEmpty({
      password: !formState.password ? constants.VALIDATION.REQUIRED : null,
      confirmPassword: getConfirmPasswordError(formState.password, formState.confirmPassword),
      username: withUsernameField && !formState.username ? constants.VALIDATION.REQUIRED : null,
    });

  return useFormHandler({
    defaultState: passwordFormState,
    validate: validateForm,
  });
};
