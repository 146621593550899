export const bentoBlue1 = '#1D3362';
export const bentoBlue2 = '#295F9C';
export const bentoBlue3 = '#4B9BD3';
export const bentoBlue4 = '#81CBF1';

export const bentoGreen1 = '#004531';
export const bentoGreen2 = '#278767';
export const bentoGreen3 = '#62AF99';
export const bentoGreen4 = '#B9CCC2';

export const bentoSlate1 = '#202E3A';
export const bentoSlate2 = '#666666';
export const bentoSlate3 = '#999999';
export const bentoSlate4 = '#C8C8C8';

export const bentoOrange1 = '#851619';
export const bentoOrange2 = '#D04827';
export const bentoOrange3 = '#EE8122';
export const bentoOrange4 = '#FFDB7C';

export const bentoTeal1 = '#024656';
export const bentoTeal2 = '#00857F';
export const bentoTeal3 = '#62AF99';
export const bentoTeal4 = '#86D0CC';

export const bentoMagenta1 = '#511C54';
export const bentoMagenta2 = '#872168';
export const bentoMagenta3 = '#C8579A';
export const bentoMagenta4 = '#E0C5DF';

export const pureWhite = '#FFFFFF';
export const offWhite = '#FAFAFA';

export const backgroundGray = '#F6F6F6';
export const bentoPurple = '#862168';

export default {
  bentoBlue1,
  bentoBlue2,
  bentoBlue3,
  bentoBlue4,
  bentoGreen1,
  bentoGreen2,
  bentoGreen3,
  bentoGreen4,
  bentoSlate1,
  bentoSlate2,
  bentoSlate3,
  bentoSlate4,
  bentoOrange1,
  bentoOrange2,
  bentoOrange3,
  bentoOrange4,
  bentoTeal1,
  bentoTeal2,
  bentoTeal3,
  bentoTeal4,
  bentoMagenta1,
  bentoMagenta2,
  bentoMagenta3,
  bentoMagenta4,
  pureWhite,
  offWhite,
  backgroundGray,
  bentoPurple,
};
