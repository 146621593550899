import { useState, useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import constants from '../constants';
import getUrlPrefix from './getUrlPrefix';
import { notify } from '../Notification';
import axios from '../axios';
import filterEmpty from './filterEmpty';

const PlanType = {
  Standard: 'standard',
  Custom: 'custom',
  PCP: 'progressive',
  EmployerMembership: 'emp',
};

const PlanTypeChoices = {
  [PlanType.Standard]: 'Standard Plan',
  [PlanType.Custom]: 'Custom Plan',
  [PlanType.PCP]: 'Progressive Cash Plan',
  [PlanType.EmployerMembership]: 'Employer Membership Plan',
};

const planTypeOptions = Object.entries(PlanTypeChoices);

const annualMaxDefaults = {
  [constants.STANDARD_PLAN_PLATINUM_PLUS]: '1500',
  [constants.STANDARD_PLAN_PLATINUM_STANDARD]: '1500',
  [constants.STANDARD_PLAN_PLATINUM_ESSENTIAL]: '750',
  [constants.STANDARD_PLAN_GOLD_PLUS]: '1000',
  [constants.STANDARD_PLAN_GOLD_STANDARD]: '1000',
  [constants.STANDARD_PLAN_GOLD_ESSENTIAL]: '600',
  [constants.STANDARD_PLAN_SILVER_PLUS]: '750',
  [constants.STANDARD_PLAN_SILVER_STANDARD]: '750',
  [constants.STANDARD_PLAN_SILVER_ESSENTIAL]: '500',
};

const childOrthoMaxDefaults = {
  [constants.STANDARD_PLAN_PLATINUM_PLUS]: '2000',
  [constants.STANDARD_PLAN_GOLD_PLUS]: '1500',
  [constants.STANDARD_PLAN_SILVER_PLUS]: '1000',
};

const isPlusPlan = (plan) =>
  [
    constants.STANDARD_PLAN_PLATINUM_PLUS,
    constants.STANDARD_PLAN_GOLD_PLUS,
    constants.STANDARD_PLAN_SILVER_PLUS,
  ].includes(plan?.plan_template_name);

export function useFetchPlanTemplates(planYear, portal) {
  const fetchPlanQuery = useQuery(
    ['plan-templates'],
    () =>
      axios
        .get(`${getUrlPrefix(portal)}/plan-template/?plan_year=${planYear}`)
        .then((response) => response.data.data),
    {
      placeholderData: [],
    }
  );
  return {
    isLoading: fetchPlanQuery.isFetching,
    planTemplates: fetchPlanQuery.data,
  };
}

export const useEobData = (portal) => {
  const eobData = useMutation(
    ({ planTemplateName, planYear }) => {

      return axios
        .get(
          `${getUrlPrefix(portal)}/plan-template/${planTemplateName}/get-eob-data/?plan_year=${planYear}`
        )
        .then((response) => response.data.data.data);
    },
    {
      onError: (err) => {
        notify(err.message, 'error', { autoHideDuration: null });
      },
    }
  );

  return {
    eobData,
  };
};

// Fetch plans
export const useFetchPlans = ({ portal, employerId, planYearId }) => {
  const queryClient = useQueryClient();

  const plansQuery = useQuery(
    ['plans', planYearId, employerId],
    () => {
      const baseUrl = `${getUrlPrefix(portal)}/plan/get-plans-data/?plan_year=${planYearId}`;
      const url = portal === constants.PORTALS.ADMIN ? `${baseUrl}&corporation_pk=${employerId}` : baseUrl;
      return axios.get(url).then((response) => response.data.data);
    },
    {
      placeholderData: {},
      enabled: portal === constants.PORTALS.ADMIN ? !!planYearId && !!employerId : !!planYearId,
    }
  );

  const planType = plansQuery.data?.plan_type;

  return {
    planLoading: plansQuery.isFetching,
    planType,
    standardPlans: planType === PlanType.Standard ? plansQuery.data?.plans : [],
    customPlan: planType === PlanType.Custom ? plansQuery.data?.plans : null,
    progressivePlan: planType === PlanType.PCP ? plansQuery.data?.plans : null,
    employerMembershipPlan:
      planType === PlanType.EmployerMembership ? plansQuery.data?.plans : null,
    canRecreate: plansQuery.data?.can_recreate_plans || false,
    cannotRecreateReason: plansQuery.data?.cannot_recreate_plans_reason,
    refreshPlans: () => {
      queryClient.invalidateQueries(['plans']);
      return Promise.resolve(true); // for promise chaining, possible TODO: obviate this by using async in callers instead
    },
  };
};

// Add UI-only flags to a plan returned from server
const makePlanVm = (plan) => {
  const planVm = {
    ...plan,
    is_selected: true,
    charge_check_fee: true,
  };

  if (plan?.charge_check_fee === false) {
    planVm.charge_check_fee = false;
  }

  if (plan?.plan_label) {
    planVm.plan_label = plan.plan_label;
  }

  if (plan && !plan.is_new_plan) {
    planVm.custom_annual_max_default = plan.annual_max || annualMaxDefaults[plan.plan_template_name];
    planVm.family_annual_max_default = plan.family_annual_max;
  }

  if (plan?.waiting_periods) {
    planVm.all_waiting_periods = plan.waiting_periods.All;
    planVm.pds_waiting_period = plan.waiting_periods.PDS;
    planVm.brs_waiting_period = plan.waiting_periods.BRS;
    planVm.mrs_waiting_period = plan.waiting_periods.MRS;
    planVm.ortho_waiting_period = plan.waiting_periods.Ortho;
  }

  if (isPlusPlan(plan)) {
    planVm.custom_ortho_default = plan.child_ortho_max || childOrthoMaxDefaults[plan.plan_template_name];
    planVm.custom_adult_ortho_default = plan.adult_ortho_max || '0.00';
  }
  return planVm;
};

function useStandardPlanValues(
  open,
  planYearId,
  standardPlans = [],
  portal = constants.PORTALS.ADMIN
) {
  const [standardPlanValues, setStandardPlanValues] = useState([]);

  // on open, if planYearId and standardPlans is provided, fill out form fields
  useEffect(() => {
    if (open && planYearId && standardPlans.length >= 0) {
      setStandardPlanValues(standardPlans.map(makePlanVm));
    }
  }, [open, planYearId, standardPlans.length]);

  const handleAddStandardPlan = (extraId) => {
    if (!standardPlanValues.some((plan) => plan.id === constants.STANDARD_PLAN_NEW_PLAN)) {
      setStandardPlanValues((plans) => [
        ...plans,
        makePlanVm({
          extra_id: extraId,
          id: constants.STANDARD_PLAN_NEW_PLAN,
          is_new_plan: true,
          template_name: '',
          template_label: '',
          template_network: '',
          template_type: '',
        }),
      ]);
    }
  };

  const handleDeleteStandardPlan = (id) => {
    setStandardPlanValues((plans) =>
      plans
        .map((plan) => {
          if ((plan.is_new_plan && plan.extra_id == id) || (!plan.is_new_plan && plan.id == id)) {
            return null;
          }
          return plan;
        })
        .filter(Boolean)
    );
  };

  const handleNewStandardPlanChange = (extraId, planTemplates) => (e) => {
    const { name, value } = e.target;
    if (standardPlanValues.some(({ extra_id }) => extra_id === extraId)) {
      setStandardPlanValues((plans) =>
        plans.map((plan) => {
          if (plan.extra_id !== extraId) {
            return plan;
          }

          if (name === 'template_network') {
            let filteredTemplates =
              value === 'any'
                ? planTemplates
                : planTemplates.filter((template) => template.network === value);
            if (plan.template_type && plan.template_type !== 'any') {
              filteredTemplates = filteredTemplates.filter(
                (template) => template.template_type === plan.template_type
              );
            }
            if (portal === constants.PORTALS.EMPLOYER) {
              filteredTemplates = filteredTemplates.filter(
                (template) => template.template_type === 'builtin'
              );
            }
            return {
              ...makePlanVm({
                [name]: value,
                extra_id: extraId,
                id: constants.STANDARD_PLAN_NEW_PLAN,
                is_new_plan: true,
                template_name: '',
                template_label: '',
                template_type: plan.template_type,
              }),
              filtered_templates: filteredTemplates,
            };
          }

          if (name === 'template_type') {
            let filteredTemplates =
              value === 'any'
                ? planTemplates
                : planTemplates.filter((template) => template.template_type === value);
            if (plan.template_network && plan.template_network !== 'any') {
              filteredTemplates = filteredTemplates.filter(
                (template) => template.network === plan.template_network
              );
            }
            if (portal === constants.PORTALS.EMPLOYER) {
              filteredTemplates = filteredTemplates.filter(
                (template) => template.template_type === 'builtin'
              );
            }
            return {
              ...makePlanVm({
                [name]: value,
                extra_id: extraId,
                id: constants.STANDARD_PLAN_NEW_PLAN,
                is_new_plan: true,
                template_name: '',
                template_label: '',
                template_network: plan.template_network,
              }),
              filtered_templates: filteredTemplates,
            };
          }

          if (name === 'template_name') {
            const matchingTemplate = planTemplates.find((template) => template.name === value);
            return {
              ...plan,
              [name]: value,
              id: value,
              plan_template_name: value,
              template_type: matchingTemplate?.template_type,
              template_label: matchingTemplate?.template_label,
              template_network: matchingTemplate?.network,
              filtered_templates: planTemplates.filter(
                (template) =>
                  template.network === matchingTemplate?.network &&
                  template.template_type === matchingTemplate?.template_type
              ),
            };
          }
        })
      );
    }
  };

  const handleStandardPlanChange = (planId) => (e) => {
    const { name, value, checked, type } = e.target;
    const keyName = name || value;
    const keyValue = type === 'checkbox' ? checked : value;
    if (standardPlanValues.some(({ id }) => id === planId)) {
      setStandardPlanValues((plans) =>
        plans.map((plan) => {
          if (plan.id !== planId) {
            return plan;
          }
          return { ...plan, [keyName]: keyValue };
        })
      );
    } else {
      // create new plan
      setStandardPlanValues((plans) =>
        plans.concat(
          makePlanVm({
            id: planId,
            [keyName]: keyValue,
          })
        )
      );
    }
  };

  function canSubmitStandardPlans(submit) {
    if (
      standardPlanValues.some(({ is_new_plan }) => is_new_plan)
    ) {
      notify('One of your plans is not configured.');
      return;
    }
    if (standardPlanValues.some(({ is_selected: selected }) => selected)) {
      submit();
    } else {
      notify('Please select at least one plan.', 'warning');
    }
  }

  function resetStandardPlanFields(planId, initialValues = undefined) {
    setStandardPlanValues((plans) => {
      return plans.map((plan) => {
        if (plan.id === planId) {
          // If there are no standard plans,
          // user is creating new set of plans, so just reset the current plan to its defaults
          if (initialValues) {
            return makePlanVm(initialValues);
          }
          const newPlan = standardPlans.length
            ? standardPlans.find(({ id }) => id === planId)
            : plan;
          return makePlanVm({ ...newPlan });
        }
        return plan;
      });
    });
  }

  return [
    standardPlanValues,
    handleStandardPlanChange,
    canSubmitStandardPlans,
    resetStandardPlanFields,
    handleAddStandardPlan,
    handleDeleteStandardPlan,
    handleNewStandardPlanChange,
  ];
}

const getStandardPlanData = (standardPlanValues) => {
  const getWaitingPeriods = (plan) =>
    filterEmpty({
      PDS: plan.pds_waiting_period,
      BRS: plan.brs_waiting_period,
      MRS: plan.mrs_waiting_period,
      Ortho: plan.ortho_waiting_period,
      All: plan.all_waiting_periods,
    });

  return standardPlanValues
    .filter(({ is_selected: selected }) => selected)
    .map((plan) =>
      filterEmpty({
        id: plan.id,
        plan_template_name: plan.plan_template_name,
        annual_max: plan.annual_max,
        family_annual_max: plan.family_annual_max,
        in_brs_covered: plan.in_brs_covered,
        in_mrs_covered: plan.in_mrs_covered,
        in_pds_covered: plan.in_pds_covered,
        oon_brs_covered: plan.oon_brs_covered,
        oon_mrs_covered: plan.oon_mrs_covered,
        oon_pds_covered: plan.oon_pds_covered,
        oon_scheme: plan.oon_scheme,
        ucr_percentile: plan.ucr_percentile,
        waiting_periods: getWaitingPeriods(plan),
        deductible_oon_only: plan.deductible_oon_only || 'false',
        deductible: plan.deductible || '0.00',
        deductible_family_cap: plan.deductible_family_cap,
        child_ortho_max: plan.child_ortho_max,
        adult_ortho_max: plan.adult_ortho_max,
        charge_check_fee: plan.charge_check_fee,
        plan_label: plan.plan_label,
      })
    );
};

const getProgressivePlanData = (planYearId, cashPlanValues) => {
  const mapSegments = (segments) =>
    segments.map(({ amount, percentage }) => ({ amount, covered_percent: percentage }));
  return {
    plan_year_id: planYearId,
    use_standard_segment_for_adult_ortho: cashPlanValues.includeAdultOrtho,
    use_standard_segment_for_child_ortho: cashPlanValues.includeChildOrtho,
    standard_segments: mapSegments(cashPlanValues.standardSegments),
    ortho_segments: cashPlanValues.separateOrtho ? mapSegments(cashPlanValues.orthoSegments) : [],
    network: cashPlanValues.network,
    waiting_periods: filterEmpty({
      All: cashPlanValues.allWaitingPeriods,
      Ortho: cashPlanValues.orthoWaitingPeriod,
    }),
    charge_check_fee: cashPlanValues.charge_check_fee,
    plan_label: cashPlanValues.plan_label,
  };
};

export function useCustomPlanValues(open, planYearId, customPlan) {
  const [customPlanValues, setCustomPlanValues] = useState({
    cleanings: 2,
    cleaningsError: '',
    exams: 1,
    examsError: '',
    bitewings: 2,
    bitewingsError: '',
    annualMax: 0,
    annualMaxError: '',
    minorOrthoMax: 0,
    minorOrthoMaxError: '',
    adultOrthoMax: 0,
    adultOrthoMaxError: '',
    networksError: '',
    platinum: false,
    gold: false,
    silver: false,
    charge_check_fee: true,
    plan_label: '',
  });

  // on open, if planYearId and customPlan is provided, fill out form fields
  useEffect(() => {
    if (open && planYearId && !!customPlan) {
      setCustomPlanValues({
        cleanings: customPlan.cleanings_per_year,
        cleaningsError: '',
        exams: customPlan.exams_per_year,
        examsError: '',
        bitewings: customPlan.x_rays,
        bitewingsError: '',
        annualMax: customPlan.annual_max,
        annualMaxError: '',
        minorOrthoMax: customPlan.child_ortho_max,
        minorOrthoMaxError: '',
        adultOrthoMax: customPlan.adult_ortho_max,
        adultOrthoMaxError: '',
        networksError: '',
        platinum: customPlan.networks.includes(constants.NETWORK_PLATINUM),
        gold: customPlan.networks.includes(constants.NETWORK_GOLD),
        silver: customPlan.networks.includes(constants.NETWORK_SILVER),
        allWaitingPeriods: customPlan.waiting_periods.All,
        orthoWaitingPeriod: customPlan.waiting_periods.Ortho,
        charge_check_fee: customPlan.charge_check_fee,
        plan_label: customPlan.plan_label,
      });
    }
  }, [open, planYearId, customPlan]);

  function handleCustomPlanChange(e) {
    const { name, value, checked } = e.target;
    const keyName = name || value;
    const keyValue = name ? value : checked;
    const errorKeyName = `${keyName}Error`;
    const errorKeyValue = '';
    setCustomPlanValues({
      ...customPlanValues,
      [keyName]: keyValue,
      [errorKeyName]: errorKeyValue,
    });
  }

  function canSubmitCustomPlans(submit) {
    if (customPlanValues.platinum || customPlanValues.gold || customPlanValues.silver) {
      submit();
    } else {
      notify('Please select at least one network.', 'warning');
    }
  }

  function getCustomPlanData() {
    return {
      plan_year: planYearId,
      annual_max: customPlanValues.annualMax,
      child_ortho_max: customPlanValues.minorOrthoMax,
      adult_ortho_max: customPlanValues.adultOrthoMax,
      exams_per_year: customPlanValues.exams,
      cleanings_per_year: customPlanValues.cleanings,
      x_rays: customPlanValues.bitewings,
      x_ray_period_in_months: customPlanValues.bitewings * 12,
      networks: constants.NETWORKS.filter((network) => customPlanValues[network]),
      waiting_periods: filterEmpty({
        All: customPlanValues.allWaitingPeriods,
        Ortho: customPlanValues.orthoWaitingPeriod,
      }),
      charge_check_fee: customPlanValues.charge_check_fee,
      plan_label: customPlanValues.plan_label,
    };
  }

  return [customPlanValues, handleCustomPlanChange, canSubmitCustomPlans, getCustomPlanData];
}

export function useProgressivePlanValues(open, planYearId, progressivePlan) {
  const mapSegments = (segments) =>
    (segments || []).map(({ amount, covered_percent }) => ({
      amount,
      percentage: covered_percent,
    }));
  const [cashPlanValues, setCashPlanValues] = useState({
    separateOrtho: false,
    standardSegments: [{ amount: '', percentage: '' }],
    orthoSegments: [{ amount: '', percentage: '' }],
    includeAdultOrtho: false,
    includeChildOrtho: false,
    network: constants.NETWORK_GOLD,
    allWaitingPeriods: '',
    orthoWaitingPeriod: '',
    charge_check_fee: true,
  });

  useEffect(() => {
    if (open && planYearId && progressivePlan) {
      const hasOrthoSegments = progressivePlan.ortho_segments?.length > 0;
      setCashPlanValues({
        separateOrtho: !!hasOrthoSegments,
        standardSegments: mapSegments(progressivePlan.standard_segments),
        orthoSegments: hasOrthoSegments
          ? mapSegments(progressivePlan.ortho_segments)
          : [{ amount: '', percentage: '' }],
        includeAdultOrtho: progressivePlan.use_standard_segment_for_adult_ortho,
        includeChildOrtho: progressivePlan.use_standard_segment_for_child_ortho,
        network: progressivePlan.network,
        allWaitingPeriods: progressivePlan.waiting_periods.All,
        orthoWaitingPeriod: progressivePlan.waiting_periods.Ortho,
        charge_check_fee: progressivePlan.charge_check_fee,
        plan_label: progressivePlan.plan_label,
      });
    }
  }, [open, planYearId, progressivePlan]);

  function handleCashPlanSegmentChange(name, value) {
    const [_, segmentType, segmentIndex, field] = name.split('-');
    const segmentKeyName = segmentType === 'standard' ? 'standardSegments' : 'orthoSegments';
    const getNewSegments = (values) =>
      values[segmentKeyName].map((segment, index) => {
        if (index === Number.parseInt(segmentIndex, 10)) {
          return { ...segment, [field]: value };
        }
        return segment;
      });
    setCashPlanValues((values) => ({
      ...values,
      [segmentKeyName]: getNewSegments(values),
    }));
  }

  function handleCashPlanChange(e) {
    const { name, value, checked, type } = e.target;
    const keyValue = type === 'checkbox' ? checked : value;

    if (!name.includes('segment') && name !== 'separateOrtho') {
      setCashPlanValues((values) => ({
        ...values,
        [name]: keyValue,
      }));
    } else if (name === 'separateOrtho') {
      setCashPlanValues((values) => ({
        ...values,
        [name]: keyValue,
        includeAdultOrtho: keyValue ? false : values.includeAdultOrtho,
        includeChildOrtho: keyValue ? false : values.includeChildOrtho,
      }));
    } else {
      handleCashPlanSegmentChange(name, keyValue);
    }
  }

  function addSegment(type) {
    const segmentKeyName = type === 'standard' ? 'standardSegments' : 'orthoSegments';
    setCashPlanValues((values) => ({
      ...values,
      [segmentKeyName]: values[segmentKeyName].concat([{ amount: '', percentage: '' }]),
    }));
  }

  function deleteSegment(type, deleteIndex) {
    const segmentKeyName = type === 'standard' ? 'standardSegments' : 'orthoSegments';
    const getNewSegments = (values) =>
      values[segmentKeyName]
        .map((segment, index) => {
          if (index !== deleteIndex) {
            return segment;
          }
          return null;
        })
        .filter((segment) => !!segment);
    setCashPlanValues((values) => ({
      ...values,
      [segmentKeyName]: getNewSegments(values),
    }));
  }

  function canSubmitCashPlans(submit) {
    const segmentsValid = (segments) =>
      segments.every(({ amount, percentage }) => amount && percentage);
    const allSegmentsValid = cashPlanValues.separateOrtho
      ? segmentsValid(cashPlanValues.orthoSegments) &&
      segmentsValid(cashPlanValues.standardSegments)
      : segmentsValid(cashPlanValues.standardSegments);
    if (allSegmentsValid && cashPlanValues.network) {
      submit();
    } else {
      notify('Please fill in all segments and select a network', 'warning');
    }
  }

  const getProgressivePlanBenefit =
    ({ portal, employerId, planYearId }) =>
      (standardSegments, orthoSegments) => {
        const urlPrefix = getUrlPrefix(portal);

        const mapSegments = (segments) =>
          segments.map(({ amount, percentage }) => ({ amount, covered_percent: percentage }));

        const data = {
          plan_year_id: planYearId,
          use_standard_segment_for_adult_ortho: cashPlanValues.includeAdultOrtho,
          use_standard_segment_for_child_ortho: cashPlanValues.includeChildOrtho,
          standard_segments: mapSegments(standardSegments),
          ortho_segments: cashPlanValues.separateOrtho ? mapSegments(orthoSegments) : [],
        };

        const url =
          portal === constants.PORTALS.ADMIN
            ? `${urlPrefix}/progressive-plan/calculate-max-benefit/?corporation_pk=${employerId}`
            : `${urlPrefix}/progressive-plan/calculate-max-benefit/`;

        return axios.post(url, data);
      };

  return {
    cashPlanValues,
    handleCashPlanChange,
    addSegment,
    deleteSegment,
    canSubmitCashPlans,
    getProgressivePlanBenefit,
  };
}

export function useEmployerPlanValues() {
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  function canSubmitEmployerPlan(submit) {
    if (selectedPlanId) {
      submit();
    }
  }

  return [selectedPlanId, setSelectedPlanId, canSubmitEmployerPlan];
}

export default {
  PlanType,
  PlanTypeChoices,
  planTypeOptions,
  useStandardPlanValues,
  useCustomPlanValues,
  useProgressivePlanValues,
  useEmployerPlanValues,
  getStandardPlanData,
  getProgressivePlanData,
  useFetchPlans,
};
