import React, { useState, useEffect } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  timeoutColor: {
    color: 'red',
    fontSize: '1.2rem',
  },
  signOutMessage: {
    width: '998px',
    border: '1px solid red',
    height: '174px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -259,
    marginTop: -86,
    backgroundColor: '#FFE6E6',
  },
});

export default function SessionTimeout() {
  const classes = useStyles();
  const [, setRender] = useState(false);

  useEffect(() => {
    const navigationEntry = window.performance.getEntriesByType(
      'navigation'
    )[0] as PerformanceNavigationTiming;
    if (navigationEntry.type === 'reload') {
      sessionStorage.removeItem('multiplelogin');
      setRender(true);
    }
  }, []);

  return (
    <Stack>
      {sessionStorage.getItem('multiplelogin') && (
        <Box
          borderColor="red"
          className={classes.signOutMessage}
          sx={{ display: 'grid', placeItems: 'center' }}
        >
          <Stack className={classes.timeoutColor}>
            <Typography variant="h2">You&apos;ve been automatically signed out</Typography>
            <Typography marginTop={1}>
              This is because another practice location has signed in with the same User ID. You can
              sign in again, but to prevent this from recurring,
            </Typography>
            <Typography>
              ask your practice admin to provide you with a dedicated user account, and then not
              share it with others.
            </Typography>
            <Typography marginTop={2}>
              If you have questions or concerns, contact Bento at 1-800-734-8484
            </Typography>
          </Stack>
        </Box>
      )}
    </Stack>
  );
}
