import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import AddServices from './AddServices';
import constants from './constants';
import BaseDialog from './BaseDialog';
import getUrlPrefix from './utilities/getUrlPrefix';
import axios from './axios';
import { notify } from './Notification';
import filterEmpty from './utilities/filterEmpty';
import formatDateForRequest from './utilities/formatDateForRequest';
import { isValidDate } from './utilities';
import { Service } from './types';

const minBenefitDate = dayjs('01-01-1970');

interface AddServicesDialogProps {
  usesServiceConverting?: boolean;
  editedService?: Service;
  open: boolean;
  onClose: () => void;
  patientId?: string;
  inOfficePlanId?: string;
  addService: (service: Service) => void;
  editService: (service?: Service) => void;
  dentalAccessPoint?: string;
  date?: string;
  practice?: { earliest_appointment_date?: string };
  practiceType: string;
  portal?: string;
  showFrequency?: boolean;
  noSettlement?: boolean;
}

interface ServiceResponse {
  data: {
    data: {
      services: Service[];
    };
  };
}

function AddServicesDialog({
  usesServiceConverting,
  editedService,
  open,
  onClose,
  patientId,
  inOfficePlanId,
  addService,
  editService,
  dentalAccessPoint,
  date,
  practice,
  practiceType,
  portal = constants.PORTALS.DENTIST,
  showFrequency = false,
  noSettlement = false,
}: AddServicesDialogProps) {
  const [allServices, setAllServices] = useState<any[] | null>(null); // TODO: Replace `any` with specific type
  const [servicesLoading, setServicesLoading] = useState<boolean>(false);

  const fetchServices = (): Promise<ServiceResponse> => {
    const allServicesUrlMap: Record<string, string> = {
      [constants.PORTALS.ADMIN]: 'mast-alto/dental-service/',
      [constants.PORTALS.DENTIST]: 'docs/all-dental-services/',
    };

    if (noSettlement) {
      return axios.get<ServiceResponse>(`${allServicesUrlMap[portal]}?type=${practiceType}`);
    }

    const body =
      practiceType === constants.NETWORK_TYPES.OUT_OF_NETWORK
        ? { type: practiceType }
        : filterEmpty({
            dental_access_point: dentalAccessPoint,
            date: formatDateForRequest(date),
            patient: patientId,
            in_office_plan: inOfficePlanId,
            practice,
            type: practiceType,
          });

    return axios.get<ServiceResponse>(
      `${getUrlPrefix(portal)}/dental-service/?${new URLSearchParams(body).toString()}`
    );
  };

  useEffect(() => {
    const shouldFetchServices = (practiceType: string): boolean => {
      if (practiceType === constants.NETWORK_TYPES.OUT_OF_NETWORK) {
        return true;
      }
      return !!dentalAccessPoint && (!!patientId || !!inOfficePlanId);
    };

    const isDateCorrect = (practiceType: string): boolean => {
      if (practiceType === constants.NETWORK_TYPES.OUT_OF_NETWORK) {
        return true;
      }
      return (
        isValidDate(date) &&
        !dayjs(date).isBefore(practice?.earliest_appointment_date || minBenefitDate)
      );
    };

    if (open && shouldFetchServices(practiceType) && isDateCorrect(practiceType)) {
      setServicesLoading(true);
      fetchServices()
        .then((response) => {
          setAllServices(response.data.data.services);
          setServicesLoading(false);
        })
        .catch((error: Error) => {
          setServicesLoading(false);
          notify(error.message, 'error');
        });
    }
  }, [open, practiceType, dentalAccessPoint, patientId, inOfficePlanId, date]);

  const shouldShowFrequency = portal === constants.PORTALS.ADMIN ? !noSettlement : showFrequency;

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      fullWidth={false}
      maxWidth="lg"
      title={editedService ? 'Edit Service' : 'Add Service'}
      showCloseButton
    >
      <AddServices
        allServices={allServices}
        servicesLoading={servicesLoading}
        usesServiceConverting={usesServiceConverting}
        editedService={editedService}
        patient={patientId}
        addService={addService}
        editService={editService}
        handleClose={onClose}
        date={date}
        portal={portal}
        showFrequency={shouldShowFrequency}
        isInDialog
      />
    </BaseDialog>
  );
}

export default AddServicesDialog;
