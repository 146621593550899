import { alpha, getContrastRatio } from '@mui/material';
import {
  backgroundGray,
  bentoOrange1,
  bentoOrange2,
  bentoOrange3,
  bentoPurple,
  bentoSlate1,
  bentoSlate2,
  bentoTeal2,
  bentoTeal3,
  bentoTeal4,
  offWhite,
  pureWhite,
} from './colors';
import filterEmpty from './utilities/filterEmpty';

const baseTheme = {
  components: {
    // applies 2 units of spacing between all grid elements and starts
    // starts their content at the top left of the container
    MuiGrid: {
      defaultProps: {
        spacing: 2,
        alignItems: 'flex-start',
      },
    },
    // applies 2 units of spacing between all stack elements
    // Uses the flexbox gap CSS property for stack spacing, looks cleaner
    MuiStack: {
      defaultProps: {
        spacing: 2,
        useFlexGap: true,
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: { direction: string } }) =>
          filterEmpty({
            alignItems: ownerState.direction === 'row' ? 'center' : null,
          }),
      },
    },
    // removes typography element from dialog titles
    // applies h1 styles
    MuiDialogTitle: {
      defaultProps: {
        disableTypography: true,
      },
      styleOverrides: {
        root: {
          padding: 24,
          paddingBottom: 8,
          fontSize: '2.25rem',
          fontFamily: ['Uni Neue', 'sans-serif'].join(', '),
          fontWeight: 300,
          lineHeight: 1,
          letterSpacing: '-0.01562em',
        },
      },
    },
    // prevents dialog from closing if you click outside the dialog
    // prevents dialogs with asynchronous content from shrinking while loader is displayed
    MuiDialog: {
      defaultProps: {
        scroll: 'body',
      },
      styleOverrides: {
        paper: {
          width: '100%',
        },
      },
    },
    // Prevents tabs from stretching to fill the parent
    // applies bento color palette to tabs
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable',
        scrollButtons: 'on',
      },
      styleOverrides: {
        indicator: {
          backgroundColor: bentoPurple,
          height: 6,
          borderRadius: '4px 4px 0 0',
        },
        flexContainer: {
          borderBottom: '4px solid #e2e2e2',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: ['Uni Neue', 'sans-serif'].join(', '),
          fontWeight: 600,
          textTransform: 'initial',
          fontSize: '1rem',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          color: '#000',
          '&:hover': {
            backgroundColor: '#eaefec',
          },
          '&.Mui-selected': {
            color: '#000',
          },
        },
      },
    },
    // allows small buttons to have variable width and all buttons to have user-specified casing
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        sizeSmall: {
          minWidth: 0,
        },
        root: {
          textTransform: 'initial',
          width: 'fit-content',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          fontFamily: ['Uni Neue', 'sans-serif'].join(', '),
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          fontFamily: ['Uni Neue', 'sans-serif'].join(', '),
          backgroundColor: pureWhite,
          color: '#797979',
        },
      },
    },
    // applies padding to the perimeter of all dialogs
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // Fixes issue https://github.com/mui/material-ui/issues/26795
          paddingTop: '8px !important',
          padding: '24px',
          overflowX: 'hidden',
          overflowY: 'hidden',
        },
      },
    },
    // makes all dialog actions left aligned with consistent button spacing
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
          flexDirection: 'row',
          padding: '0 24px 24px 24px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          background: pureWhite,
          padding: 15,
        },
        root: {
          background: pureWhite,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: pureWhite,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(224, 224, 224, 1)',
        },
        head: {
          backgroundColor: offWhite,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: '140%',
          color: bentoSlate1,
        },
        head: {
          fontSize: '0.85rem',
          fontWeight: 600,
        },
        body: {
          fontSize: '0.8125rem',
          fontWeight: 400,
          maxWidth: '400px !important',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          marginTop: '4px',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          padding: '4px 24px',
          fontWeight: 400,
          backgroundColor: bentoSlate1,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginBottom: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: 1.2,
        },
      },
    },
  },
  palette: {
    primary: {
      light: bentoTeal4,
      main: bentoTeal3,
      dark: bentoTeal2,
      contrastText: pureWhite,
    },
    secondary: {
      light: bentoOrange3,
      main: bentoOrange2,
      dark: bentoOrange1,
      contrastText: pureWhite,
    },
    background: {
      default: backgroundGray,
    },
    text: {
      primary: bentoSlate1,
    },
    action: {
      main: bentoPurple,
      light: alpha(bentoPurple, 0.5),
      dark: alpha(bentoPurple, 0.9),
      contrastText: getContrastRatio(bentoPurple, '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Open Sans', 'Verdana', 'sans-serif'].join(', '),
    button: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
    },
    h1: {
      fontSize: '2.25rem',
      fontFamily: ['Uni Neue', 'sans-serif'].join(', '),
      marginTop: 12,
      paddingBottom: 4,
      fontWeight: 300,
    },
    h2: {
      fontSize: '1.5rem',
      fontFamily: ['Uni Neue', 'sans-serif'].join(', '),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.25rem',
      fontFamily: ['Uni Neue', 'sans-serif'].join(', '),
      lineHeight: 1.4,
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.15rem',
      fontFamily: ['Uni Neue', 'sans-serif'].join(', '),
      lineHeight: 1.4,
      fontWeight: 400,
    },
    h6: {
      fontSize: '1rem',
      lineHeight: 1.25,
      fontWeight: 700,
    },
    subtitle1: {
      color: bentoSlate2,
    },
    body1Next: {
      fontWeight: 400,
    },
    body2Next: {
      fontWeight: 300,
    },
  },
};

export default baseTheme;
