import React, { useEffect, useState, useMemo } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Button, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from './axios';
import { bentoTeal2, pureWhite } from './colors';
import constants from './constants';
import { notify } from './Notification';
import Loader from './Loader';
import ServicesDropdown from './ServicesDropdown';
import deepCopy from './utilities/deepCopy';
import ToothQuadrant from './ToothQuadrant';
import PrimaryButton from './PrimaryButton';
import UsdTextField from './fields/UsdTextField';
import ServiceFrequencyInfo from './ServiceFrequencyInfo';
import TypographyError from './TypographyError';
import BentoIcon from './BentoIcon';

const DEFAULT_TOOTH_STATE = {
  selected: false,
  surface_select: {
    distal: {
      selected: false,
    },
    occlusal: {
      selected: false,
    },
    buccal: {
      selected: false,
    },
    mesial: {
      selected: false,
    },
    lingual: {
      selected: false,
    },
  },
};

const generateDefaultTeethState = () => {
  const teeth = {};
  for (let i = 1; i <= 32; i++) {
    teeth[i] = deepCopy(DEFAULT_TOOTH_STATE);
  }
  for (const i of [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
  ]) {
    teeth[i] = deepCopy(DEFAULT_TOOTH_STATE);
  }
  return teeth;
};

const defaultQuadrantState = {
  upper_left: {
    selected: false,
  },
  upper_right: {
    selected: false,
  },
  lower_left: {
    selected: false,
  },
  lower_right: {
    selected: false,
  },
};

const defaultArchState = {
  upper: {
    selected: false,
  },
  lower: {
    selected: false,
  },
};

const useStyles = makeStyles({
  error: {
    margin: '8px 12px 0',
    fontSize: '.75rem',
    lineHeight: '1em',
  },
  quadrants: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  quadrant: {
    padding: '10px 3px',
    fontSize: '14px',
    color: bentoTeal2,
    border: `1px solid ${bentoTeal2}`,
    textAlign: 'center',
    width: '49%',
    cursor: 'pointer',
    marginBottom: '8px',
  },
  arches: {
    width: '100%',
    marginTop: '10px',
  },
  arch: {
    padding: '10px 3px',
    fontSize: '14px',
    color: bentoTeal2,
    border: `1px solid ${bentoTeal2}`,
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    marginBottom: '8px',
  },
  selected: {
    color: pureWhite,
    backgroundColor: bentoTeal2,
  },
  footerText: {
    paddingTop: '0 !important',
  },
  feeText: {
    paddingBottom: '0 !important',
  },
});

function AddServices(props) {
  const classes = useStyles();
  const { quadrant, arch, selected } = classes;

  const [loader, setLoader] = useState(true);
  const [toothSelect, setToothSelect] = useState(generateDefaultTeethState());
  const [quadrantSelect, setQuadrantSelect] = useState(defaultQuadrantState);
  const [archSelect, setArchSelect] = useState(defaultArchState);
  const [addServiceButtonIsDisabled, setAddServiceButtonIsDisabled] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [servicesError, setServicesError] = useState(null);
  const [officeFee, setOfficeFee] = useState('');
  const [officeFeeError, setOfficeFeeError] = useState('');
  const [officeFeeText, setOfficeFeeText] = useState('');

  const selectedServiceDetail = useMemo(() => {
    if (!selectedService || !props.allServices) {
      return {};
    }
    return props.allServices.find(({ code }) => code === selectedService.value) || {};
  }, [selectedService, props.allServices]);

  const initialize = () => {
    setOfficeFeeText(props.allServices?.data?.data?.office_fee_text);
    setLoader(false);
    setAddServiceButtonIsDisabled(false);
  };

  const convertAsClaimExtract = (service) => {
    return axios
      .post('mast-alto/dental-service/as-claim-extract/', service)
      .then((response) => response.data.data);
  };

  const convertAsServiceData = (serviceExtract) => {
    return axios
      .post('mast-alto/dental-service/as-service-data/', { services: [serviceExtract] })
      .then((response) => response.data)
      .catch((error) => {
        notify(error.message, 'error');
      });
  };

  const { editedService, usesServiceConverting } = props;

  useEffect(() => {
    initialize();
    if (editedService && usesServiceConverting) {
      convertAsServiceData(editedService).then((response) => {
        setQuadrantSelect({ ...defaultQuadrantState, ...response.data[0]?.quadrant_select });
        setArchSelect({ ...defaultArchState, ...response.data[0]?.arch_select });
        setToothSelect({ ...generateDefaultTeethState(), ...response.data[0]?.tooth_select });
        if (props.allServices) {
          setSelectedService({
            value: response.data[0]?.code,
            label: `${response.data[0]?.code} - ${
              props.allServices.find((item) => item.code === response.data[0]?.code).description
            }`,
          });
        }
        setOfficeFee(response.data[0]?.office_fee);
      });
    }
    if (editedService && !usesServiceConverting) {
      setQuadrantSelect({ ...defaultQuadrantState, ...editedService.quadrant_select });
      setArchSelect({ ...defaultArchState, ...editedService.arch_select });
      setToothSelect({ ...generateDefaultTeethState(), ...editedService.tooth_select });
      if (props.allServices) {
        setSelectedService({
          value: editedService.code,
          label: `${editedService.code} - ${
            props.allServices.find((item) => item.code === editedService.code).description
          }`,
        });
      }
      setOfficeFee(editedService.office_fee);
    }
  }, [editedService, props.allServices]);

  const defaultOpts = { addAnother: false };
  const addService = (opts = defaultOpts) => {
    const { addService: addServiceFn, editService: editServiceFn, usesServiceConverting } = props;
    if (selectedService) {
      setAddServiceButtonIsDisabled(true);
      const service = props.allServices.find(({ code }) => code === selectedService.value);

      // Validate that office fee exists if service has no price
      if (!service.price && !officeFee) {
        setOfficeFeeError(constants.VALIDATION.REQUIRED);
        return;
      }

      service.tooth_select = toothSelect;
      service.quadrant_select = quadrantSelect;
      service.arch_select = archSelect;
      service.service = service.service || 'N/A';
      service.is_final = true;
      // If the user overrides existing service price
      if (officeFee) {
        service.price = null;
        service.office_fee = officeFee;
      }
      setLoader(true);

      if (!usesServiceConverting) {
        if (editedService) {
          editServiceFn(service);
          setLoader(false);
          setAddServiceButtonIsDisabled(false);
        } else {
          addServiceFn(service, opts.addAnother)
            .then((value) => {
              if (opts.addAnother) {
                setSelectedService(null);
                setQuadrantSelect(defaultQuadrantState);
                setArchSelect(defaultArchState);
                setToothSelect(generateDefaultTeethState());
                setOfficeFee('');
              }
              notify(`Added service ${selectedService.value}`, 'success');
            })
            .catch((error) => {
              notify(error.message, 'error');
            })
            .finally(() => {
              setLoader(false);
              setAddServiceButtonIsDisabled(false);
            });
        }
      } else {
        convertAsClaimExtract(service)
          .then((resultService) => {
            if (editedService) {
              editServiceFn(resultService);
              props.handleClose();
            } else {
              addServiceFn(resultService, opts.addAnother).then(() => {
                if (opts.addAnother) {
                  setSelectedService(null);
                  setQuadrantSelect(defaultQuadrantState);
                  setArchSelect(defaultArchState);
                  setToothSelect(generateDefaultTeethState());
                  setOfficeFee('');
                } else {
                  props.handleClose();
                }
                notify(`Added service ${selectedService.value}`, 'success');
              });
            }
          })
          .catch((error) => {
            notify(error.message, 'error');
          })
          .finally(() => {
            setLoader(false);
            setAddServiceButtonIsDisabled(false);
          });
      }
    } else {
      setServicesError('Please select a service.');
    }
  };

  const addAnotherService = async () => {
    await addService({ addAnother: true });
  };

  const onToothSelection = (tooth) => {
    setToothSelect((prevToothSelect) => {
      const newToothSelect = deepCopy(prevToothSelect);
      newToothSelect[tooth].selected = !prevToothSelect[tooth].selected;
      if (!newToothSelect[tooth].selected) {
        newToothSelect[tooth] = deepCopy(DEFAULT_TOOTH_STATE);
      }
      return newToothSelect;
    });
  };

  const onSurfaceSelection = (tooth) => {
    const surfaces = {
      D: 'distal',
      O: 'occlusal',
      B: 'buccal',
      M: 'mesial',
      L: 'lingual',
    };

    setToothSelect((prevToothSelect) => {
      const newToothSelect = deepCopy({ ...generateDefaultTeethState(), ...prevToothSelect });
      const previousToothSelectCopy = deepCopy({
        ...generateDefaultTeethState(),
        ...prevToothSelect,
      });
      const surface = surfaces[tooth.surface];
      newToothSelect[tooth.num].selected = true;
      newToothSelect[tooth.num].surface_select = {
        ...newToothSelect[tooth.num].surface_select,
        [surface]: {
          selected: !previousToothSelectCopy[tooth.num]?.surface_select[surface]?.selected,
        },
      };
      return newToothSelect;
    });
  };

  const onQuadrantSelection = (quadrant) => {
    setQuadrantSelect((prevQuadrantSelect) => {
      const newQuadrantSelect = deepCopy(prevQuadrantSelect);
      newQuadrantSelect[quadrant].selected = !prevQuadrantSelect[quadrant].selected;
      return newQuadrantSelect;
    });
  };

  const onArchSelection = (arch) => {
    setArchSelect((prevArchSelect) => {
      const newArchSelect = deepCopy(prevArchSelect);
      newArchSelect[arch].selected = !prevArchSelect[arch].selected;
      return newArchSelect;
    });
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <ServicesDropdown
          services={props.allServices}
          selectedService={selectedService}
          handleChange={setSelectedService}
          isInDialog={props.isInDialog}
          disabled={props.servicesLoading}
        />
        {servicesError && <Typography color="error">{servicesError}</Typography>}
        {selectedServiceDetail.fee_entry_reason_text && (
          <Typography color="error">{selectedServiceDetail.fee_entry_reason_text}</Typography>
        )}
      </Grid>
      {props.showFrequency && (
        <Grid item xs={12}>
          <ServiceFrequencyInfo
            portal={props.portal}
            selectedService={selectedService}
            patient={props.patient}
            date={props.date}
          />
        </Grid>
      )}
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Stack direction="column" spacing={0}>
            <Stack direction="row" spacing={0}>
              <Stack
                direction="row"
                sx={{
                  borderRight: 1,
                  borderRightColor: '#e0e0e0',
                  borderBottom: 1,
                  borderBottomColor: '#e0e0e0',
                  paddingBottom: 1.3,
                  paddingRight: 2,
                }}
                spacing={0}
              >
                {(function () {
                  const quadrants = [];
                  for (let i = 1; i <= 8; i++) {
                    quadrants.push(
                      <ToothQuadrant
                        toothPositionTop={false}
                        num={i}
                        onSurfaceClick={onSurfaceSelection}
                        onToothClick={onToothSelection}
                        key={`teeth-row-1-tooth${i}`}
                        tooth_select={toothSelect}
                      />
                    );
                  }
                  return quadrants;
                })()}
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderBottom: 1,
                  borderBottomColor: '#e0e0e0',
                  paddingBottom: 1.3,
                  paddingLeft: 2,
                }}
                spacing={0}
              >
                {(function () {
                  const quadrants = [];
                  for (let i = 9; i <= 16; i++) {
                    quadrants.push(
                      <ToothQuadrant
                        toothPositionTop={false}
                        num={i}
                        onSurfaceClick={onSurfaceSelection}
                        onToothClick={onToothSelection}
                        key={`teeth-row-1-tooth${i}`}
                        tooth_select={toothSelect}
                      />
                    );
                  }
                  return quadrants;
                })()}
              </Stack>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Stack
                direction="row"
                sx={{
                  borderRight: 1,
                  borderRightColor: '#e0e0e0',
                  paddingTop: 1.3,
                  paddingRight: 2,
                }}
                spacing={0}
              >
                {(function () {
                  const quadrants = [];
                  for (let i = 32; i >= 25; i--) {
                    quadrants.push(
                      <ToothQuadrant
                        toothPositionTop
                        num={i}
                        onSurfaceClick={onSurfaceSelection}
                        onToothClick={onToothSelection}
                        key={`teeth-row-2-tooth${i}`}
                        tooth_select={toothSelect}
                      />
                    );
                  }
                  return quadrants;
                })()}
              </Stack>
              <Stack direction="row" sx={{ paddingLeft: 2, paddingTop: 1.3 }} spacing={0}>
                {(function () {
                  const quadrants = [];
                  for (let i = 24; i >= 17; i--) {
                    quadrants.push(
                      <ToothQuadrant
                        toothPositionTop
                        num={i}
                        onSurfaceClick={onSurfaceSelection}
                        onToothClick={onToothSelection}
                        key={`teeth-row-2-tooth${i}`}
                        tooth_select={toothSelect}
                      />
                    );
                  }
                  return quadrants;
                })()}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="column" spacing={0} sx={{ marginTop: 2 }}>
            <Stack direction="row" spacing={0}>
              <Stack
                direction="row"
                sx={{
                  borderRight: 1,
                  borderRightColor: '#e0e0e0',
                  borderBottom: 1,
                  borderBottomColor: '#e0e0e0',
                  paddingBottom: 1.3,
                  paddingRight: 2,
                }}
                spacing={0}
              >
                {['A', 'B', 'C', 'D', 'E'].map((i) => (
                  <ToothQuadrant
                    toothPositionTop={false}
                    num={i}
                    onSurfaceClick={onSurfaceSelection}
                    onToothClick={onToothSelection}
                    key={`teeth-row-3-1-tooth${i}`}
                    tooth_select={toothSelect}
                  />
                ))}
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderBottom: 1,
                  borderBottomColor: '#e0e0e0',
                  paddingBottom: 1.3,
                  paddingLeft: 2,
                }}
                spacing={0}
              >
                {['F', 'G', 'H', 'I', 'J'].map((i) => (
                  <ToothQuadrant
                    toothPositionTop={false}
                    num={i}
                    onSurfaceClick={onSurfaceSelection}
                    onToothClick={onToothSelection}
                    key={`teeth-row-3-2-tooth${i}`}
                    tooth_select={toothSelect}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Stack
                direction="row"
                sx={{
                  borderRight: 1,
                  borderRightColor: '#e0e0e0',
                  paddingTop: 1.3,
                  paddingRight: 2,
                }}
                spacing={0}
              >
                {['T', 'S', 'R', 'Q', 'P'].map((i) => (
                  <ToothQuadrant
                    toothPositionTop
                    num={i}
                    onSurfaceClick={onSurfaceSelection}
                    onToothClick={onToothSelection}
                    key={`teeth-row-4-1-tooth${i}`}
                    tooth_select={toothSelect}
                  />
                ))}
              </Stack>
              <Stack
                direction="row"
                sx={{
                  paddingTop: 1.3,
                  paddingLeft: 2,
                }}
                spacing={0}
              >
                {['O', 'N', 'M', 'L', 'K'].map((i) => (
                  <ToothQuadrant
                    toothPositionTop
                    num={i}
                    onSurfaceClick={onSurfaceSelection}
                    onToothClick={onToothSelection}
                    key={`teeth-row-4-2-tooth${i}`}
                    tooth_select={toothSelect}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.quadrants}>
            <div
              className={clsx(quadrant, quadrantSelect.upper_right.selected && selected)}
              onClick={() => onQuadrantSelection('upper_right')}
            >
              <span>
                Upper Right
                <br />
                Quadrant (10)
              </span>
            </div>
            <div
              className={clsx(quadrant, quadrantSelect.upper_left.selected && selected)}
              onClick={() => onQuadrantSelection('upper_left')}
            >
              <span>
                Upper Left
                <br />
                Quadrant (20)
              </span>
            </div>
            <div
              className={clsx(quadrant, quadrantSelect.lower_right.selected && selected)}
              onClick={() => onQuadrantSelection('lower_right')}
            >
              <span>
                Lower Right
                <br />
                Quadrant (40)
              </span>
            </div>
            <div
              className={clsx(quadrant, quadrantSelect.lower_left.selected && selected)}
              onClick={() => onQuadrantSelection('lower_left')}
            >
              <span>
                Lower Left
                <br />
                Quadrant (30)
              </span>
            </div>
          </div>
          <div className={classes.arches}>
            <div
              className={clsx(arch, archSelect.upper.selected && selected)}
              onClick={() => onArchSelection('upper')}
            >
              <span>Maxillary Arch (01)</span>
            </div>
            <div
              className={clsx(arch, archSelect.lower.selected && selected)}
              onClick={() => onArchSelection('lower')}
            >
              <span>Mandibular Arch (02)</span>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        {selectedService && (
          <Grid item xs={4} className={classes.feeText}>
            {!selectedServiceDetail.price ? (
              <UsdTextField
                id="office-fee"
                value={officeFee}
                label="Office Fee"
                onChange={(e) => {
                  setOfficeFee(e.target.value);
                  setOfficeFeeError('');
                  setAddServiceButtonIsDisabled(false);
                }}
                fullWidth={false}
                error={!!officeFeeError}
                helperText={officeFeeError}
              />
            ) : (
              <Typography className={classes.topSpacing1}>
                {selectedServiceDetail.negotiated_fee_label} ${selectedServiceDetail.price}
              </Typography>
            )}
          </Grid>
        )}

        {officeFeeText && (
          <Grid item xs={12}>
            <TypographyError className={classes.topSpacing1}>{officeFeeText}</TypographyError>
          </Grid>
        )}
        {selectedServiceDetail.fee_entry_footer_text && (
          <Grid item xs={12} className={classes.footerText}>
            <Typography>{selectedServiceDetail.fee_entry_footer_text}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} className={classes.topSpacing2}>
        <Divider sx={{ marginBottom: 2 }} />
        <Stack direction="row">
          <PrimaryButton
            id="btn-add-service"
            onClick={addService}
            disabled={addServiceButtonIsDisabled}
            sx={{ minWidth: 150 }}
          >
            Done
          </PrimaryButton>
          {!editedService && (
            <Button
              id="btn-add-another-service"
              color="primary"
              startIcon={<BentoIcon name="circle-plus" variant="solid" />}
              onClick={addAnotherService}
              sx={{ minWidth: 150 }}
              variant="outlined"
            >
              Add another service
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default AddServices;
