import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import axios from './axios';
import { notify } from './Notification';
import BentoLogo from './BentoLogo';
import mixpanelLogger from './mixpanelLogger';
import useQueryString from './hooks/useQuery';
import UserPasswordForm, { usePasswordForm } from './UserPasswordForm';
import constants from './constants';

const portalNames = {
  [constants.PORTALS.ADMIN]: 'Admin Portal',
  [constants.PORTALS.DENTIST]: 'Dentist Portal',
  [constants.PORTALS.EMPLOYER]: 'Employer Portal',
  [constants.PORTALS.MEMBER]: 'Member Portal',
};

interface UpdatePasswordProps {
  portal: string;
}

function UpdatePassword({ portal }: UpdatePasswordProps) {
  const history = useHistory();
  const queryString = useQueryString();
  const verificationCode = queryString.get('code');

  const { formState, handleChange, errors, validate: validateForm } = usePasswordForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    setIsSubmitting(true);
    notify('Changing password...', 'success');
    axios
      .patch('/password-reset/complete/', {
        password: formState.password,
        code: verificationCode,
      })
      .then(() => {
        // For now this is the only portal not using mixpanel
        if (portal !== constants.PORTALS.ADMIN) {
          mixpanelLogger.track('forgotPasswordCompleted', { verificationCode });
        }
        notify(
          'Your password has been updated. You can now sign in using your new password.',
          'success'
        );
        history.push('/sign-in');
      })
      .catch((error) => {
        setIsSubmitting(false);
        notify(error.message, 'error');
      });
  };

  return (
    <Box width={800} margin="15% auto">
      <Stack component={Paper} padding={6}>
        <BentoLogo portalText={portalNames[portal]} />
        <Typography variant="h3" mt={3}>
          Update Password
        </Typography>
        <UserPasswordForm
          onSubmit={handleSubmit}
          submitLabel="Reset Password"
          formState={formState}
          handleChange={handleChange}
          errors={errors}
          disabled={isSubmitting}
        />
      </Stack>
    </Box>
  );
}

export default UpdatePassword;
