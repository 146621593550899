import axios from 'axios';

declare module 'axios' {
  export interface AxiosStatic {
    logout: () => void;
    getBaseUrl: () => string;
    setAPIToken: (token: string) => void;
    getAPIToken: () => string;
    deleteAPIToken: () => void;
    isAuthenticated: () => boolean;
  }
}

axios.logout = () => {
  axios.deleteAPIToken();
  window.location.href = '/';
};

axios.getBaseUrl = () => {
  return axios.defaults.baseURL || '';
};

axios.setAPIToken = (token) => {
  sessionStorage.token = `Token ${token}`;
};

axios.getAPIToken = () => sessionStorage.token;

axios.deleteAPIToken = () => {
  sessionStorage.removeItem('token');
  localStorage.setItem('clearSessionStorage', Date.now().toString());
};

axios.isAuthenticated = () => !!sessionStorage.token;

axios.defaults.baseURL = `${window.location.origin}/api/`;

const redirectToNotFound = () => {
  window.location.hash = '/not-found';
};

const HTTP_STATUS_MESSAGE_MAP = {
  401: 'You are not authorized. Please sign in.',
  403: 'You do not have permission to perform this action.',
  404: 'Not found.',
  413: 'Sorry, that file is too big. Please contact Bento at smile@bento.net.',
  503: 'Bento is currently down for maintenance, please try again later.',
};

const UNKNOWN_STATUS_ERROR_MESSAGE =
  "Sorry! There's a problem on our end. We’re on it. If the issue persists, contact Bento at smile@bento.net.";

axios.interceptors.request.use(
  (config) => {
    if (axios.isAuthenticated()) {
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = axios.getAPIToken();
    }
    return config;
  },
  (error) => {
    let message = UNKNOWN_STATUS_ERROR_MESSAGE;
    if (error.request) {
      const status = error.request.status as keyof typeof HTTP_STATUS_MESSAGE_MAP;
      if (status in HTTP_STATUS_MESSAGE_MAP) {
        message = HTTP_STATUS_MESSAGE_MAP[status as keyof typeof HTTP_STATUS_MESSAGE_MAP];
      }
    }

    // eslint-disable-next-line no-param-reassign
    error.message = message;

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message = UNKNOWN_STATUS_ERROR_MESSAGE;
    if (error.response) {
      const { status } = error.response;

      if (status === 404) {
        redirectToNotFound();
      }

      if (error.response.data?.errors || error.response.data?.data?.errors) {
        message = error.response.data.errors ?? error.response.data?.data?.errors;
        if (message === null || message === undefined) {
          message = UNKNOWN_STATUS_ERROR_MESSAGE;
        } else if (typeof message === 'object') {
          message = Object.values(message).join(', ');
        }
      } else if (status in HTTP_STATUS_MESSAGE_MAP) {
        if (status === 401) {
          sessionStorage.setItem('multiplelogin', 'true');
          axios.logout();
        } else {
          message = HTTP_STATUS_MESSAGE_MAP[status as keyof typeof HTTP_STATUS_MESSAGE_MAP];
        }
      }
    }

    // eslint-disable-next-line no-param-reassign
    error.message = message;
    // eslint-disable-next-line no-param-reassign
    error.name = ''; // Hide error name field introduced in axios v1 from user, not useful

    return Promise.reject(error);
  }
);

export default axios;
