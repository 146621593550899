import React, { useState } from 'react';
import clsx from 'clsx';
import { CardElement } from '@stripe/react-stripe-js';

import useTheme from '@mui/styles/useTheme';
import makeStyles from '@mui/styles/makeStyles';
import { alpha, Theme } from '@mui/material/styles';
import { bentoSlate4 } from './colors';

const useCreditCardStyles = makeStyles((theme: Theme) => ({
  cardElementValid: {
    padding: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
  },
  cardElementInvalid: {
    padding: 10,
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 5,
  },
  cardElementDisabled: {
    padding: 10,
    border: `2px solid ${bentoSlate4}`,
    borderRadius: 5,
  },
}));

interface CreditCardInputProps {
  disabled?: boolean;
}

function CreditCardInput({ disabled = false }: CreditCardInputProps) {
  const theme = useTheme();
  const classes = useCreditCardStyles();
  const [isValidCard, setIsValidCard] = useState(true);

  return (
    <CardElement
      className={clsx(classes.cardElementValid, {
        [classes.cardElementDisabled]: disabled,
        [classes.cardElementInvalid]: !isValidCard,
      })}
      options={{
        disabled,
        style: {
          base: {
            color: (theme as Theme).palette.text.primary,
            fontSize: `${(theme as Theme).typography.htmlFontSize}px`,
            fontFamily: (theme as Theme).typography.fontFamily,
            '::placeholder': {
              color: alpha((theme as Theme).palette.text.primary, 0.42),
            },
          },
          invalid: {
            color: (theme as Theme).palette.error.main,
          },
        },
      }}
      onChange={(event: any) => {
        const isInvalidCard = !!event.error;
        setIsValidCard(!isInvalidCard);
      }}
    />
  );
}

export default CreditCardInput;
