import React from 'react';
import MaskedTextField from './MaskedTextField';

interface PercentFieldProps {
  id?: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value: string | number;
  minAllowed?: number;
  maxAllowed?: number;
  [key: string]: any; // Allow additional props to be passed to the MaskedTextField component
}

function PercentField({
  id,
  label,
  onChange,
  value,
  minAllowed = 0,
  maxAllowed = 100,
  ...rest
}: PercentFieldProps) {
  return (
    <MaskedTextField
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      {...rest}
      patternType="numeric"
      suffix="%"
      allowNegative={false}
      decimalScale={0}
      isAllowed={({ value: percent }) => {
        if (percent === '') {
          return true;
        }
        const num = Number.parseInt(percent, 10);
        return num >= minAllowed && num <= maxAllowed;
      }}
    />
  );
}

export default PercentField;
