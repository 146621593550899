import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import NotificationWrapper from './NotificationWrapper';

interface NotifyOptions {
  autoHideDuration?: number;
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  [key: string]: any;
}

function Notification() {
  const [open, setOpen] = useState<boolean>(false);
  const [variant, setVariant] = useState<'info' | 'success' | 'error' | 'warning'>('info');
  const [message, setMessage] = useState<string | null>(null);
  const [options, setOptions] = useState<NotifyOptions>({});

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const notify = (
    newMessage: string,
    newVariant: 'info' | 'success' | 'error' | 'warning',
    newOptions: NotifyOptions = {}
  ) => {
    setOpen(true);
    setVariant(newVariant);
    setMessage(newMessage);
    setOptions(newOptions);
  };

  useEffect(() => {
    notifyFn = notify;
  }, []);

  const messageWithLineBreaks = (message || '').split('\n').map((text, index, array) => (
    <React.Fragment key={text}>
      {text}
      {index !== array.length - 1 && <br />}
    </React.Fragment>
  ));
  return (
    <Snackbar
      id="notify"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      {...options}
    >
      <div>
        <NotificationWrapper
          onClose={handleClose}
          variant={variant}
          message={messageWithLineBreaks}
        />
      </div>
    </Snackbar>
  );
}

let notifyFn: (
  message: string,
  variant: 'info' | 'success' | 'error' | 'warning',
  options?: NotifyOptions
) => void;

export function notify(
  message: string,
  // eslint-disable-next-line default-param-last
  variant: 'info' | 'success' | 'error' | 'warning' = 'info',
  options?: NotifyOptions
) {
  if (notifyFn) {
    notifyFn(message, variant, options);
  }
}

export default Notification;
