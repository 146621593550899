/**
 * Constructs a return URL from the provided query parameters.
 *
 * @param query - A URLSearchParams instance containing the query parameters.
 * @returns The return URL string, or '/' if no 'return-url' parameter is present.
 */
const getReturnUrl = (query: URLSearchParams): string => {
  if (!query.has('return-url')) {
    return '/';
  }

  const url = query.get('return-url');
  query.delete('return-url');

  return `${url}?${query.toString()}`;
};

export default getReturnUrl;
