import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import logo from '../public/logo.png';

interface LogoProps {
  portalText?: string;
  isLinked?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1),
  },
  portalText: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1rem',
    letterSpacing: '0.0075em',
    whiteSpace: 'nowrap',
  },
}));

export default function Logo({ portalText, isLinked = false }: LogoProps) {
  const classes = useStyles();

  let image = <img height="32px" alt="logo" src={logo} />;
  if (isLinked) {
    image = <a href="https://bento.net">{image}</a>;
  }

  return (
    <div className={portalText ? classes.logoContainer : ''}>
      {image}
      {portalText && <Typography className={classes.portalText}>{portalText}</Typography>}
    </div>
  );
}
