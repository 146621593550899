import dayjs, { Dayjs } from 'dayjs';

const formatDate = (
  date: string | Date | null | undefined | Dayjs,
  format: string = 'MMM D, YYYY',
  invalidValue: string = 'Not a valid date'
): string => {
  if (!date) {
    return '';
  }

  const newDate = dayjs(date, ['MM/DD/YYYY', 'YYYY-MM-DD', 'MMM D, YYYY']);
  if (newDate.isValid()) {
    return newDate.format(format);
  }
  return invalidValue;
};

export default formatDate;
