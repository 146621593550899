import React, { ReactNode } from 'react';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, Theme } from '@mui/material';
import BentoIcon from './BentoIcon';

const useStylesNotificationWrapper = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: '#CCF2CC',
    color: '#00A757',
    borderBottom: '4px solid #00A757',
    borderRadius: 4,
  },
  error: {
    backgroundColor: '#FFE6E6',
    color: '#ec0000',
    borderBottom: '4px solid #ff9595',
  },
  info: {
    backgroundColor: '#b0e7ff',
    color: '#1d3362',
    borderBottom: '4px solid #5aceff',
  },
  warning: {
    backgroundColor: ' #ffeddd',
    color: '#de6a00',
    borderBottom: '4px solid #f7af6c',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon: Record<'success' | 'warning' | 'error' | 'info', string> = {
  success: 'circle-check',
  warning: 'triangle-exclamation',
  error: 'circle-exclamation',
  info: 'circle-info',
};

interface NotificationWrapperProps {
  message: ReactNode;
  onClose: () => void;
  variant: 'error' | 'info' | 'success' | 'warning';
  [key: string]: any;
}

function NotificationWrapper({ message, onClose, variant, ...other }: NotificationWrapperProps) {
  const classes = useStylesNotificationWrapper();
  const iconName = variantIcon[variant];

  return (
    <Alert
      className={clsx(classes.message, classes[variant])}
      aria-describedby="client-alert"
      icon={<BentoIcon name={iconName} className={clsx(classes.icon, classes.iconVariant)} />}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} size="large">
          <BentoIcon className={classes.icon} variant="regular" name="xmark" />
        </IconButton>,
      ]}
      severity={variant}
      {...other}
    >
      {message}
    </Alert>
  );
}

export default NotificationWrapper;
