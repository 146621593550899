import { styled, Typography, TypographyProps } from '@mui/material';
import { bentoPurple } from './colors';

export const TooltipList = styled('ol')({
  marginLeft: 8,
  marginRight: 20,
  '& > li': {
    marginTop: 6,
  },
});

export const TooltipHeader = styled(Typography)<TypographyProps & { color?: string }>(
  ({ color }) => ({
    fontSize: '0.9rem',
    marginBottom: 12,
    marginTop: 15,
    marginLeft: 4,
    color: color || '#000',
  })
);

export const TooltipTitle = styled(Typography)({
  fontWeight: 600,
  color: bentoPurple,
});

export const TooltipContent = styled('div')({
  maxWidth: '22vw',
});

export const TooltipParagraph = styled(Typography)({
  marginTop: 6,
  marginRight: 20,
});
