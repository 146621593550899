import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// Disabled service-worker for now because we are not taking advantage
// of offline features and it causes reload issues when updating
serviceWorker.unregister();
