import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { pureWhite, bentoSlate1 } from './colors';

const selectedBtnColor = 'rgba(129, 203, 241, 0.3)';

const activeListItemStyle = {
  cursor: 'pointer',
  backgroundColor: selectedBtnColor,
  color: pureWhite,
  borderLeft: '4px solid #81cbf1',
  '&:hover': {
    borderLeft: '4px solid #81cbf1',
  },
};

const inactiveListItemStyle = {
  cursor: 'pointer',
  borderLeft: `4px solid ${bentoSlate1}`,
  '&:hover': {
    backgroundColor: selectedBtnColor,
    color: pureWhite,
    borderLeft: '4px solid rgba(0, 0, 0, 0)',
  },
};

interface NavMenuItemProps {
  id: string;
  title: string;
  route: string | string[];
  isActive: boolean;
  items?: { title: string; route: string; showWarning?: boolean }[];
  showWarningFor?: boolean;
  onClick?: () => void;
}

function NavMenuItem({
  id,
  title,
  route,
  isActive,
  items = [],
  showWarningFor = false,
  onClick = () => {},
}: NavMenuItemProps) {
  const location = useLocation();
  const history = useHistory();

  const navigateToRoute = () => {
    const newUrl = Array.isArray(route) ? route[0] : route;
    history.push(newUrl);
    onClick();
  };

  return (
    <>
      <ListItem sx={isActive ? activeListItemStyle : inactiveListItemStyle}>
        <ListItemText
          id={`nav-menu-${id}`}
          primary={title}
          primaryTypographyProps={{
            fontFamily: 'inherit',
            fontWeight: 600,
          }}
          onClick={navigateToRoute}
        />
      </ListItem>
      {items.map((item) => {
        const subItemActive = !isActive && location.pathname === item.route;
        const subListItemStyle = subItemActive ? activeListItemStyle : inactiveListItemStyle;
        return (
          <ListItem
            key={item.title}
            sx={{ ...subListItemStyle, paddingTop: 1, paddingBottom: 1, paddingLeft: 3 }}
            onClick={() => history.push(item.route)}
          >
            {item.showWarning && showWarningFor && (
              <ListItemIcon sx={{ minWidth: 32 }}>
                <WarningRoundedIcon color="error" />
              </ListItemIcon>
            )}
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                fontFamily: 'inherit',
                fontWeight: 400,
              }}
            />
          </ListItem>
        );
      })}
    </>
  );
}

export default NavMenuItem;
