import React, { ChangeEvent, JSX } from 'react';
import { NumericFormat, PatternFormat, NumberFormatValues } from 'react-number-format';

import OutlinedTextField from './OutlinedTextField';

interface MaskedTextFieldProps {
  id: string;
  name?: string;
  format?: string;
  placeholder?: string;
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  patternType?: 'pattern' | 'numeric';
  setValue?: () => void;
  isClearable?: boolean;
  customInput?: React.ComponentType<JSX.IntrinsicElements['input']>;
  maxLength?: number;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export default function MaskedTextField({
  id,
  name,
  format = '',
  placeholder,
  value,
  onChange,
  label,
  patternType = 'pattern',
  setValue,
  isClearable,
  customInput = OutlinedTextField,
  maxLength,
  onClick,
  onFocus,
  ...other
}: MaskedTextFieldProps) {
  const Component = patternType === 'numeric' ? NumericFormat : PatternFormat;

  const isAllowed = (values: NumberFormatValues) => {
    if (!maxLength) return true;

    const { value } = values;
    return value.length <= maxLength;
  };

  return (
    <Component
      id={id}
      name={name}
      customInput={customInput}
      placeholder={placeholder}
      format={format}
      value={value}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name,
          } as EventTarget & HTMLInputElement,
        } as ChangeEvent<HTMLInputElement>);
      }}
      isClearable={isClearable}
      setValue={() => onChange({ target: { value: '', name } } as ChangeEvent<HTMLInputElement>)}
      label={label}
      isAllowed={patternType === 'numeric' ? isAllowed : undefined}
      {...(patternType === 'pattern' && maxLength ? { maxLength } : {})}
      onClick={onClick}
      onFocus={onFocus}
      {...other}
    />
  );
}