import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import axios from './axios';
import { notify } from './Notification';
import formatDateForRequest from './utilities/formatDateForRequest';
import isEmpty from './utilities/isEmpty';
import getUrlPrefix from './utilities/getUrlPrefix';

type FrequencyInfo = {
  service_type?: string;
  frequency?: string;
  per_quadrant?: boolean;
  per_tooth?: boolean;
  per_surface?: boolean;
  age_limit?: string | null;
  frequency_info_not_available_str?: string;
};

type ServiceFrequencyInfoProps = {
  selectedService: { value: string } | null;
  patient: any; // TODO: Replace with a specific type if known
  date: Date;
  portal: string;
};

export default function ServiceFrequencyInfo({
  selectedService,
  patient,
  date,
  portal,
}: ServiceFrequencyInfoProps) {
  const [frequencyInfo, setFrequencyInfo] = useState<FrequencyInfo>({});

  useEffect(() => {
    if (selectedService) {
      axios
        .post(`/${getUrlPrefix(portal)}/dental-service/${selectedService.value}/frequency/`, {
          patient,
          date: formatDateForRequest(date),
        })
        .then((response) => {
          setFrequencyInfo(response.data.data);
        })
        .catch((error) => {
          notify(error.message, 'error');
        });
    } else {
      setFrequencyInfo({});
    }
  }, [selectedService, patient, date, portal]);

  if (isEmpty(frequencyInfo)) {
    return null;
  }

  if (frequencyInfo.frequency_info_not_available_str) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography>{frequencyInfo.frequency_info_not_available_str}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography variant="subtitle1">Type</Typography>
        <Typography>{frequencyInfo.service_type}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle1">Frequency</Typography>
        <Typography>{frequencyInfo.frequency}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle1">Per Quadrant</Typography>
        <Typography>{frequencyInfo.per_quadrant ? 'Yes' : 'No'}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle1">Per Tooth</Typography>
        <Typography>{frequencyInfo.per_tooth ? 'Yes' : 'No'}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle1">Per Surface</Typography>
        <Typography>{frequencyInfo.per_surface ? 'Yes' : 'No'}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle1">Age Limit</Typography>
        <Typography>{frequencyInfo.age_limit || 'None'}</Typography>
      </Grid>
    </Grid>
  );
}
