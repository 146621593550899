import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import axios from '../axios';
import mixpanel from '../mixpanelLogger';
import { notify } from '../Notification';

type UseInitializerProps = {
  portal: string;
  useStripe?: boolean;
  onAfterInit?: () => void;
};

type HelloResponse = {
  version_number: string;
  instance: string;
  debug: boolean;
  server_datetime: string;
  stripe_public_api_key?: string;
  mixpanel_api_token?: string;
  success?: boolean;
  google_maps_api_key?: string;
  google_maps_map_id?: string;
  source_commit?: string;
  source_branch?: string;
  environment?: string;
};

const initMixpanel = (apiKey: string, debug: boolean) => {
  mixpanel.init(apiKey, { debug });
};

function useInitializer({
  portal,
  useStripe = false,
  onAfterInit = () => {},
}: UseInitializerProps) {
  const [environment, setEnvironment] = useState<string | null>(null);
  const [version, setVersion] = useState<string>('');
  const [debug, setDebug] = useState<boolean>(false);
  const [serverDateTime, setServerDateTime] = useState<string | null>(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [fullResponse, setFullResponse] = useState<HelloResponse | null>(null);

  const helloUrl = `hello/?mixpanel_project=${portal}`;

  const hello = async () => {
    try {
      const response = await axios.get<HelloResponse>(helloUrl);
      const data = response.data;

      setVersion(data.version_number);
      setEnvironment(data.instance);
      setDebug(data.debug);
      setServerDateTime(data.server_datetime);

      if (useStripe && data.stripe_public_api_key) {
        setStripe(await loadStripe(data.stripe_public_api_key));
      }

      if (data.mixpanel_api_token) {
        initMixpanel(data.mixpanel_api_token, data.debug);
      }

      setFullResponse(data);
    } catch {
      notify(
        'Unable to connect to Bento. Please check your internet connection and try again.',
        'info'
      );
    }
  };

  useEffect(() => {
    hello().then(onAfterInit);
  }, []);

  // This code will override the date sent to Mixpanel
  // DEV ONLY - Comment this block out when testing new Mixpanel events
  // TODO: How do we get the actual date to Mixpanel?

  useEffect(() => {
    const setMockDate = async () => {
      if (debug && serverDateTime) {
        const MockDate = await import('mockdate');
        MockDate.set(serverDateTime);
      }
    };
    setMockDate();
  }, [debug, serverDateTime]);

  return {
    environment,
    version,
    debug,
    serverDateTime,
    stripe,
    fullResponse,
    reinitialize: () => {
      hello().then(onAfterInit);
    },
  };
}

export default useInitializer;
