import dayjs, { Dayjs } from 'dayjs';

/**
 * Checks if the provided input is a valid date.
 *
 * @param input - The date input to validate, as a string or Date object.
 * @returns `true` if the input is a valid date, `false` otherwise.
 */
const isValidDate = (input: string | Date | null | undefined | Dayjs): boolean => {
  if (!input) {
    return false;
  }
  return dayjs(input).isValid();
};

export default isValidDate;
