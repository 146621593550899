import React, { ReactNode } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Tip from './Tip';

const useStyles = makeStyles({
  tooltip: {
    marginRight: '16px',
    '&:last-child': {
      marginRight: 0,
    },
  },
});

interface TableButtonProps extends ButtonProps {
  id?: string;
  tip?: string;
  children: ReactNode;
  [key: string]: any; // Allow additional props to be passed to the Button component
}

export default function TableButton({ id, tip = '', children, ...rest }: TableButtonProps) {
  const classes = useStyles();

  const button = (
    <Button id={id} color="primary" size="small" {...rest}>
      {children}
    </Button>
  );

  if (tip) {
    return (
      <Tip id={`${id}-tooltip`} className={classes.tooltip} title={tip}>
        {button}
      </Tip>
    );
  }

  return button;
}
