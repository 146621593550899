import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Paper, Stack } from '@mui/material';
import { axios, BentoLogo, constants, notify, PrimaryButton } from '@bento-dental/core';
import { useQuery } from '@bento-dental/core/hooks';
import { OutlinedTextField, PasswordField } from '@bento-dental/core/fields';
import { getReturnUrl } from '@bento-dental/core/utilities';

function SignIn({ login }) {
  const history = useHistory();
  const queryParams = useQuery();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const onKeyPress = (target) => {
    if (target.charCode === 13 || target.key === 'Enter') {
      handleLoginButton(target);
    }
  };

  const handleLoginButton = () => {
    let isValid = true;

    if (!email) {
      isValid = false;
      setEmailError(constants.VALIDATION.REQUIRED);
    }
    if (!password) {
      isValid = false;
      setPasswordError(constants.VALIDATION.REQUIRED);
    }
    if (!isValid) {
      return;
    }

    setEmailError('');
    setPasswordError('');

    axios
      .post('mast-alto/admin-sign-in/', {
        username: email,
        password,
        request_client: 'admin_portal',
      })
      .then((response) => {
        login(response.data);
        history.push(getReturnUrl(queryParams));
      })
      .catch((error) => {
        notify(error.toString());
      });
  };

  return (
    <Box width={500} margin="15% auto">
      <Paper sx={{ padding: 6 }}>
        <BentoLogo portalText="Admin Portal" />
        <Stack component="form" marginTop={4} autoComplete="off" noValidate>
          <OutlinedTextField
            id="username"
            autoComplete="username"
            name="userName"
            label="Username"
            placeholder="Enter Username"
            type="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              setEmailError('');
            }}
            error={!!emailError}
            helperText={emailError}
            onKeyPress={onKeyPress}
          />
          <PasswordField
            id="current-password"
            autoComplete="current-password"
            name="password"
            label="Password"
            value={password}
            type="password"
            placeholder="Enter Password"
            onChange={(event) => {
              setPassword(event.target.value);
              setPasswordError('');
            }}
            error={!!passwordError}
            helperText={passwordError}
            onKeyPress={onKeyPress}
          />
          <PrimaryButton id="sign-in-btn" onClick={handleLoginButton}>
            Sign in
          </PrimaryButton>
        </Stack>
      </Paper>
    </Box>
  );
}

export default SignIn;
