import React, { useState, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import { bentoBlue1, bentoBlue3 } from './colors';

interface InternalLinkProps {
  to: string;
  children: ReactNode;
  onClick?: () => void;
  [key: string]: any;
}

function InternalLink({ to, children, onClick, ...sxProps }: InternalLinkProps) {
  const [color, setColor] = useState<string>(bentoBlue3);

  const handleMouseEnter = () => {
    setColor(bentoBlue1);
  };

  const handleMouseLeave = () => {
    setColor(bentoBlue3);
  };

  return (
    <Box
      color={color}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      display="inline"
      fontWeight={600}
      {...sxProps}
    >
      <NavLink to={to} onClick={onClick} style={{ textDecoration: 'none', color: 'inherit' }}>
        {children}
      </NavLink>
    </Box>
  );
}

export default InternalLink;
