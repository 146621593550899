import React, { useMemo } from 'react';
import Select, { createFilter, GroupBase, SingleValue } from 'react-select';
import { FixedSizeList } from 'react-window';
import { bentoTeal3 } from './colors';

type Service = {
  code: string;
  description: string;
};

type Option = {
  label: string;
  value: string;
};

type ServicesMenuProps = {
  options: Option[];
  children: React.ReactNode[];
  getValue: () => Option[];
};

function ServicesMenu({ options, children, getValue }: ServicesMenuProps) {
  const height = 35;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <FixedSizeList
      height={250}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      overscanCount={3}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
}

type ServicesDropdownProps = {
  services: Service[];
  selectedService: SingleValue<Option>;
  handleChange: (selected: SingleValue<Option>) => void;
  isInDialog?: boolean;
  [key: string]: any; // Additional props for Select component
};

function ServicesDropdown({
  services,
  selectedService,
  handleChange,
  isInDialog = false,
  ...rest
}: ServicesDropdownProps) {
  const options = useMemo(() => {
    if (services?.length) {
      return services.map((service) => ({
        label: `${service.code} - ${service.description}`,
        value: service.code,
      }));
    }
    return [];
  }, [services]);

  return (
    <Select
      placeholder="Select a service"
      styles={{
        container: (provided) => ({
          ...provided,
          marginTop: '1px',
        }),
        option: (provided) => ({
          ...provided,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'inline-block',
          fontSize: '0.8125rem',
        }),
        control: (provided) => ({
          ...provided,
          borderColor: 'rgba(0, 0, 0, 0.23)',
          boxShadow: 'none',
          width: 425,
          '&:hover': {
            borderColor: bentoTeal3,
            boxShadow: `0 0 0 1px ${bentoTeal3}`,
          },
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 99,
        }),
      }}
      menuPortalTarget={!isInDialog ? document.body : undefined}
      isClearable
      filterOption={createFilter({ ignoreAccents: false })}
      components={{ ServicesMenu }}
      options={options}
      value={selectedService}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default ServicesDropdown;
