import React, { ReactNode } from 'react';
import { Theme, Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

/*
   Simple wrapper for Typography component to prevent having to declare
   the same properties everywhere and allow some customization.
*/

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

interface TypographyErrorProps extends TypographyProps {
  children: ReactNode;
}

function TypographyError({ children, ...other }: TypographyErrorProps) {
  const classes = useStyles();

  return (
    <Typography variant="subtitle1" {...other} className={classes.error}>
      {children}
    </Typography>
  );
}

export default TypographyError;
