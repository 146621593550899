import React from 'react';
import { Stack, Typography, styled } from '@mui/material';
import RichTooltip from './RichTooltip';
import HelpIcon from '@mui/icons-material/Help';

interface ServiceBillableStatusProps {
  service: {
    billable_status: string;
    billable_status_description: string;
    settlement_history: string[];
  };
  withLabel?: boolean;
}

interface StatusColor {
  primary: 'primary';
  error: 'error';
  inherit: 'inherit';
}

type BillableStatusType = (typeof BillableStatus)[keyof typeof BillableStatus];

const BillableStatus = {
  Included: 'Included in plan',
  Partial: 'Partially covered',
  Covered: 'Covered',
  NotCovered: 'Not covered',
  NotBillable: 'Not billable',
};

const positiveStatuses = [BillableStatus.Included, BillableStatus.Partial, BillableStatus.Covered];
const negativeStatuses = [BillableStatus.NotCovered, BillableStatus.NotBillable];

const RichTooltipContent = styled('div')({ maxWidth: '18vw', fontSize: '0.8125rem' });

const getStatusColor = (status: BillableStatusType): keyof StatusColor => {
  if (positiveStatuses.includes(status)) {
    return 'primary';
  }
  if (negativeStatuses.includes(status)) {
    return 'error';
  }

  return 'inherit';
};

export default function ServiceBillableStatus({
  service,
  withLabel = true,
}: ServiceBillableStatusProps) {
  const statusColor = getStatusColor(service.billable_status);

  const statusReason =
    service.billable_status === BillableStatus.NotBillable
      ? service.billable_status_description
      : service.settlement_history?.map((item) => <div>{item}</div>);

  return (
    <Stack spacing={1} direction="row">
      {withLabel && (
        <Typography fontSize="inherit" color={statusColor}>
          {service.billable_status}
        </Typography>
      )}
      {statusReason && (
        <RichTooltip
          placement="top"
          content={
            <RichTooltipContent>
              <Typography fontWeight={700} fontSize="inherit">
                Explanation: {service.billable_status}
              </Typography>
              <Typography fontSize="inherit">{statusReason}</Typography>
            </RichTooltipContent>
          }
        >
          <HelpIcon fontSize="small" name="circle-question" color={statusColor} />
        </RichTooltip>
      )}
    </Stack>
  );
}
