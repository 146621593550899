import constants from '../constants';

/**
 * Retrieves the URL prefix for a given portal type.
 *
 * @param portal - The portal type (e.g., 'admin', 'employer', 'dentist').
 * @returns The URL prefix associated with the portal.
 * @throws Error if the portal is invalid or not recognized.
 */
const getUrlPrefix = (portal: string): string => {
  const prefixes: Record<string, string> = {
    [constants.PORTALS.ADMIN]: 'mast-alto',
    [constants.PORTALS.EMPLOYER]: 'corp',
    [constants.PORTALS.DENTIST]: 'docs',
  };

  if (!prefixes[portal]) {
    throw new Error('You need to provide a portal (one of "dentist", "admin", or "employer")');
  }

  return prefixes[portal];
};

export default getUrlPrefix;
