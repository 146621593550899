import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface BentoIconProps extends SvgIconProps {
  variant?: 'light' | 'regular' | 'bold';
  name: string;
}

function BentoIcon({ variant = 'light', name, ...props }: BentoIconProps) {
  return (
    <SvgIcon {...props}>
      <use xlinkHref={`./icons/${variant}.svg#${name}`} />
    </SvgIcon>
  );
}

export default BentoIcon;
