import React, { useState, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { bentoBlue1, bentoBlue3 } from './colors';

// Define the props type
interface ExternalLinkProps {
  href: string;
  children: ReactNode;
  onClick?: () => void;
  [key: string]: any;
}

function ExternalLink({ href, children, onClick, ...rest }: ExternalLinkProps) {
  const [color, setColor] = useState<string>(bentoBlue3);

  const handleMouseEnter = () => {
    setColor(bentoBlue1);
  };

  const handleMouseLeave = () => {
    setColor(bentoBlue3);
  };

  return (
    <Box
      color={color}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      fontWeight={600}
      display="inline"
      {...rest}
    >
      <a
        href={href}
        onClick={onClick}
        style={{ textDecoration: 'none', color: 'inherit' }}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    </Box>
  );
}

ExternalLink.displayName = 'ExternalLink';

export default ExternalLink;
