import React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import makeStyles from '@mui/styles/makeStyles';
import Tip from './Tip';

const useStyles = makeStyles({
  icon: {
    marginTop: 5,
  },
  iconPosition: {
    marginTop: '-10px',
    position: 'absolute',
  },
});

type ServiceHelpTipProps = {
  id: string;
  service: {
    settlement_history: string[];
  };
  placement?: 'left' | 'right' | 'top' | 'bottom';
  iconStyle?: boolean;
};

export default function ServiceHelpTip({
  id,
  service,
  placement = 'left',
  iconStyle = false,
}: ServiceHelpTipProps) {
  const classes = useStyles();
  const settlementEvents =
    service.settlement_history.length > 0
      ? service.settlement_history.map((event) => <div key={event}>{event}</div>)
      : 'No information available';

  return (
    <Tip id={id} title={settlementEvents} placement={placement}>
      <HelpIcon color="primary" className={iconStyle ? classes.iconPosition : classes.icon} />
    </Tip>
  );
}
