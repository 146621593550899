import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import BentoIcon from '../BentoIcon';

interface OutlinedTextFieldProps {
  id?: string;
  name?: string;
  value?: string | number | null;
  className?: string;
  length?: number;
  type?: string;
  select?: boolean;
  label?: string;
  inputProps?: object;
  isClearable?: boolean;
  tooltipText?: string; // New prop for tooltip text
  setValue?: (value: string) => void;
  children?: any;
  onChange?: (event: any) => any;
  [key: string]: any; // Allow additional props
}

export default function OutlinedTextField({
  value,
  className = '',
  length = 100,
  type = 'text',
  inputProps = {},
  isClearable = false,
  setValue = () => {},
  tooltipText,
  ...rest
}: OutlinedTextFieldProps) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      autoComplete="off"
      value={value}
      inputProps={{ ...inputProps, maxLength: length, type }}
      className={className}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment:
          // eslint-disable-next-line no-nested-ternary
          isClearable && !!value ? (
            <IconButton onClick={() => setValue('')}>
              <BentoIcon name="xmark" />
            </IconButton>
          ) : !value && tooltipText ? (
            <Tooltip title={tooltipText} placement="top">
              <span>
                <IconButton size="small" disabled>
                  <BentoIcon color="primary" name="circle-question" variant="solid" />
                </IconButton>
              </span>
            </Tooltip>
          ) : null,
      }}
      {...rest}
    />
  );
}
