import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import axios from './axios';
import { getReturnUrl } from './utilities';
import useQueryString from './hooks/useQuery';

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

export default function PublicRoute({ component: Component, ...rest }: PublicRouteProps) {
  // If the user gets passed a sign-in URL with a return URL parameter while already logged in
  const queryParams = useQueryString();

  if (axios.isAuthenticated()) {
    return <Redirect to={getReturnUrl(queryParams)} />;
  }

  return <Route {...rest} render={(props: any) => <Component {...props} />} />;
}
