/**
 * Takes an object and returns a new object, minus any empty keys (null, undefined, or '').
 *
 * @param obj - The object to filter.
 * @returns A new object with empty keys removed.
 */
const filterEmpty = <T extends Record<string, any>>(obj: T): Partial<T> => {
  // Convert object to key-value pair array
  const entries = Object.entries(obj);

  // Filter out null, undefined, or empty entries
  const filteredEntries = entries.filter(([_, value]) => {
    return (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      (typeof value !== 'object' || Object.keys(value).length !== 0)
    );
  });

  // Create an object from the filtered array
  return Object.fromEntries(filteredEntries) as Partial<T>;
};

export default filterEmpty;
