import React, { useContext } from 'react';

export const StorageContext = React.createContext<Map<string, any>>(new Map());

const useStorage = () => {
  const storage = useContext(StorageContext);

  return {
    hasStorage(key: string): boolean {
      return storage.has(key);
    },
    getStorage<T>(key: string): T | undefined {
      return storage.get(key);
    },
    setStorage<T>(key: string, value: T): void {
      storage.set(key, value);
    },
    removeStorage(key: string): boolean {
      return storage.delete(key);
    },
  };
};

// Custom hook to directly access a specific storage key
export const useStorageKey = <T>(key: string): T | undefined => {
  return useStorage().getStorage<T>(key);
};

export default useStorage;
