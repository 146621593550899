import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const formatDateTime = (
  dateTime: string | Date | null | undefined,
  format: string = 'MMM D, YYYY hh:mm A',
  isUTC: boolean = false
): string => {
  if (!dateTime) {
    return '';
  }
  const value = isUTC ? dayjs.utc(dateTime) : dayjs(dateTime);
  return value.isValid() ? value.format(format) : 'INVALID';
};

export default formatDateTime;
