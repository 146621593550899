import React from 'react';
import { Stack, styled } from '@mui/material';
import Quadrant from './Quadrant';
import Tooth from './Tooth';

interface ToothQuadrantProps {
  onSurfaceClick: (num: number, surface: string) => void;
  tooth_select: boolean;
  onToothClick: (num: number) => void;
  num: number;
  toothPositionTop?: boolean;
}

const ToothContainer = styled(Stack)({
  flexWrap: 'wrap',
  alignItems: 'center',
  width: 70,
});

function ToothQuadrant({
  onSurfaceClick,
  tooth_select: toothSelect,
  onToothClick,
  num,
  toothPositionTop = false,
}: ToothQuadrantProps) {
  if (toothPositionTop) {
    return (
      <ToothContainer>
        <Tooth num={num} onToothClick={onToothClick} tooth_select={toothSelect} />
        <Quadrant num={num} onSurfaceClick={onSurfaceClick} tooth_select={toothSelect} />
      </ToothContainer>
    );
  }

  return (
    <ToothContainer>
      <Quadrant num={num} onSurfaceClick={onSurfaceClick} tooth_select={toothSelect} />
      <Tooth num={num} onToothClick={onToothClick} tooth_select={toothSelect} />
    </ToothContainer>
  );
}

export default ToothQuadrant;
