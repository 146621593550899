import { useEffect, useState } from 'react';
import axios from '../axios';

// Define the type for the login parameter
interface LoginParams {
  token: string;
}

function useLoginHandler() {
  // Define the state for login status, initialized based on session storage
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!sessionStorage.getItem('token'));

  // Function to handle user login
  const login = async ({ token }: LoginParams): Promise<void> => {
    sessionStorage.removeItem('multiplelogin'); // Clear any "multiple login" flags
    axios.setAPIToken(token); // Set the API token in the axios instance
    setIsLoggedIn(axios.isAuthenticated()); // Update the state based on authentication status
  };

  /**
   * Handles session storage synchronization across tabs
   * Triggered whenever the `storage` event occurs on the window
   */
  const handleSessionStorage = (event: StorageEvent): void => {
    const isLoggingOut = localStorage.getItem('clearSessionStorage');
    if (event.key === 'clearSessionStorage') {
      // User logged out in another tab
      sessionStorage.removeItem('token'); // Clear session token
      setIsLoggedIn(false); // Update the state
    } else if (event.key === 'getSessionStorage' && !isLoggingOut) {
      // Another tab requested session storage data
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage)); // Send session storage
      localStorage.removeItem('sessionStorage'); // Clean up the request
    } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
      // Current tab has an empty session storage
      const data = JSON.parse(event.newValue || '{}'); // Parse the received session storage
      if (data) {
        Object.entries(data).forEach(([key, val]) => {
          sessionStorage.setItem(key, val as string); // Repopulate session storage
        });
        if (sessionStorage.getItem('token')) {
          setIsLoggedIn(true); // Update the state if a token exists
        }
      }
    }
  };

  // Set up the storage event listener and synchronize session storage
  useEffect(() => {
    // Clean up any stale session data
    localStorage.removeItem('clearSessionStorage');
    sessionStorage.removeItem('multiplelogin');

    // Add event listener for storage events
    window.addEventListener('storage', handleSessionStorage);

    // Request session storage from other tabs on mount if the current session storage is empty
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', Date.now().toString());
    } else if (sessionStorage.getItem('token')) {
      setIsLoggedIn(true); // Update state if a token is already present
    }

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleSessionStorage);
    };
  }, []);

  return {
    isLoggedIn,
    login,
  };
}

export default useLoginHandler;
