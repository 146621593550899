interface Location {
  pathname: string;
  search: string;
}

const createReturnUrl = (newUrl: string, location: Location): string => {
  const { pathname, search } = location;
  const newSearch = search.slice(1);
  if (pathname !== '/') {
    return `${newUrl}/?return-url=${pathname}${newSearch ? `&${newSearch}` : ''}`;
  }
  return newUrl;
};

export default createReturnUrl;
