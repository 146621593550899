import React from 'react';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
}));

interface OptionChipsProps<T> {
  options: T[];
  getOptionText?: (option: T) => string;
}

function OptionChips<T>({
  options,
  getOptionText = (option) => String(option),
}: OptionChipsProps<T>) {
  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {options.map((option) => (
        <Chip
          id={`option-chip-${getOptionText(option)}`}
          className={classes.chip}
          key={`option-chip-${getOptionText(option)}`}
          label={getOptionText(option)}
        />
      ))}
    </div>
  );
}

export default OptionChips;
