import React from 'react';
import { Stack, styled } from '@mui/material';
import { bentoTeal2, pureWhite } from './colors';

type QuadrantProps = {
  tooth_select: Record<number, { surface_select: Record<string, { selected: boolean }> }> | null;
  num: number;
  onSurfaceClick: (data: { num: number; surface: string }) => void;
};

const Surface = styled('div')<{ selected: boolean }>(({ selected }) => ({
  color: selected ? pureWhite : bentoTeal2,
  backgroundColor: selected ? bentoTeal2 : pureWhite,
  border: `1px solid ${bentoTeal2}`,
  height: 18,
  width: 18,
  margin: 1,
  fontSize: 11,
  cursor: 'pointer',
  textAlign: 'center',
}));

function Quadrant({ tooth_select: toothSelect, num, onSurfaceClick }: QuadrantProps) {
  const selectedSurfaces: Record<'distal' | 'occlusal' | 'buccal' | 'mesial' | 'lingual', boolean> =
    {
      distal: false,
      occlusal: false,
      buccal: false,
      mesial: false,
      lingual: false,
    };

  const surfaceList = toothSelect && toothSelect[num] ? toothSelect[num].surface_select : {};
  Object.keys(surfaceList).forEach((key) => {
    selectedSurfaces[key as 'distal' | 'occlusal' | 'buccal' | 'mesial' | 'lingual'] =
      !!surfaceList[key].selected;
  });

  return (
    <Stack spacing={0}>
      <Stack direction="row" justifyContent="center">
        <Surface
          selected={selectedSurfaces.buccal}
          onClick={() => onSurfaceClick({ num, surface: 'B' })}
        >
          B
        </Surface>
      </Stack>
      <Stack direction="row" spacing={0}>
        <Surface
          selected={selectedSurfaces.mesial}
          onClick={() => onSurfaceClick({ num, surface: 'M' })}
        >
          M
        </Surface>
        <Surface
          selected={selectedSurfaces.occlusal}
          onClick={() => onSurfaceClick({ num, surface: 'O' })}
        >
          O
        </Surface>
        <Surface
          selected={selectedSurfaces.distal}
          onClick={() => onSurfaceClick({ num, surface: 'D' })}
        >
          D
        </Surface>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Surface
          selected={selectedSurfaces.lingual}
          onClick={() => onSurfaceClick({ num, surface: 'L' })}
        >
          L
        </Surface>
      </Stack>
    </Stack>
  );
}

export default Quadrant;
