import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

interface TipProps extends Omit<TooltipProps, 'title'> {
  id?: string;
  title: string;
  placement?: TooltipProps['placement'];
  children: React.ReactElement;
}

function Tip({ id, title, placement = 'left', children, ...rest }: TipProps) {
  return (
    <Tooltip title={title} placement={placement} {...rest}>
      <Box display="inline" id={id}>
        {children}
      </Box>
    </Tooltip>
  );
}

Tip.displayName = 'Tip';

export default Tip;
