import React from 'react';
import MaskedTextField from './MaskedTextField';

interface UsdTextFieldProps {
  id?: string;
  name: string;
  label?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

function UsdTextField({ id, name, label, value, onChange, ...rest }: UsdTextFieldProps) {
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    // select all text when field receives focus
    e.currentTarget.select();
  };

  return (
    <MaskedTextField
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      patternType="numeric"
      prefix="$"
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      onFocus={handleFocus}
      {...rest}
    />
  );
}

export default UsdTextField;