import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InternalLink from './InternalLink';

export default function PageNotFound() {
  return (
    <Box
      flex
      alignContent="center"
      justifyContent="center"
      sx={{
        margin: 'auto',
        height: 'calc(100vh - 150px)',
      }}
    >
      <Paper
        variant="outlined"
        sx={{ background: '#fff', padding: 8, display: 'flex', flexDirection: 'column', gap: 3 }}
      >
        <div>
          <Typography align="center" fontSize={50} variant="h4">
            404
          </Typography>
          <Typography fontWeight={600} sx={{ textTransform: 'uppercase' }} align="center">
            Page not found
          </Typography>
        </div>
        <Box align="center">
          Please click <InternalLink to="/">here</InternalLink> to return to the home page.
        </Box>
      </Paper>
    </Box>
  );
}
