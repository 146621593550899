import React, { ReactNode, JSX, ElementType, ReactElement } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper, { PaperProps } from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Draggable from 'react-draggable';
import makeStyles from '@mui/styles/makeStyles';
import BentoIcon from './BentoIcon';

import isEmpty from './utilities/isEmpty';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

interface Actions {
  [key: string]: () => void;
}

interface BaseDialogProps {
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  title?: string | ReactElement;
  dialogName?: string;
  actions?: Actions;
  disableSubmit?: boolean;
  submitTip?: string;
  children: ReactNode;
  actionButtonComponent?: React.ElementType;
  override?: boolean;
  [key: string]: any; // Allow additional props
}

function PaperComponent(props: PaperProps<ElementType>) {
  return (
    <Draggable handle=".draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStylesBaseDialog = makeStyles({
  dialogTitle: {
    cursor: 'move',
    width: '100%',
    display: 'block',
  },
});

export default function BaseDialog({
  open,
  onClose,
  showCloseButton = false,
  title = '',
  dialogName = 'dialog',
  actions = {},
  disableSubmit = false,
  submitTip = '',
  children,
  actionButtonComponent = PrimaryButton,
  override = false,
  ...rest
}: BaseDialogProps): JSX.Element {
  const classes = useStylesBaseDialog();

  const { negativeAction, negativeActionTitle } = rest;

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby={`${dialogName}-title`}
      fullWidth
      PaperComponent={PaperComponent}
      {...rest}
    >
      <DialogTitle id={`${dialogName}-title`}>
        <Stack direction="row">
          <Box flexGrow={1} className="draggable-dialog-title">
            <Typography variant="h2" className={classes.dialogTitle}>
              {title}
            </Typography>
          </Box>
          {showCloseButton && (
            <Box>
              <Button
                variant="text"
                aria-label="close"
                onClick={onClose}
                color="inherit"
                startIcon={<BentoIcon variant="regular" name="xmark" />}
                sx={{ display: { xs: 'none', md: 'flex' } }}
              >
                Close
              </Button>
              <IconButton
                aria-label="close-icon"
                color="inherit"
                onClick={onClose}
                size="large"
                sx={{ display: { xs: 'flex', md: 'none' } }}
              >
                <BentoIcon variant="regular" name="xmark" />
              </IconButton>
            </Box>
          )}
        </Stack>
      </DialogTitle>
      {override ? children : <DialogContent>{children}</DialogContent>}
      {!isEmpty(actions) && (
        <DialogActions>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row">
              {Object.entries(actions).map(([text, handler], index) => {
                const ButtonComponent = index === 0 ? actionButtonComponent : Button;
                const disabled = disableSubmit && index === 0;
                return (
                  <ButtonComponent
                    id={`${dialogName}-action-${text.toLowerCase()}`}
                    key={text}
                    onClick={handler}
                    disabled={disabled}
                    tip={submitTip}
                  >
                    {text}
                  </ButtonComponent>
                );
              })}
            </Stack>
            {negativeActionTitle && (
              <SecondaryButton id={'negative-action-button'} onClick={negativeAction}>
                {negativeActionTitle}
              </SecondaryButton>
            )}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}
