import { useState } from 'react';

type Errors = Record<string, string>;

export default function useFormErrors() {
  const [errors, setErrors] = useState<Errors>({});

  const setError = (key: string, value: string): void => {
    setErrors((prevErrors) => ({ ...prevErrors, [key]: value }));
  };

  const clearError = (key: string): void => {
    setError(key, '');
  };

  return {
    errors,
    setErrors,
    setError,
    clearError,
  };
}
