/**
 * Used to deep copy a JSON formatted object.
 *
 * Since the object must be JSON formatted, functions and other non-standard
 * JSON types will not be copied.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Deep_Clone
 */
const deepCopy = <T>(jsonObject: T): T => {
  return JSON.parse(JSON.stringify(jsonObject));
};

export default deepCopy;
