import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import axios from './axios';
import createReturnUrl from './utilities/createReturnUrl';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  location?: Location;
}

export default function PrivateRoute({
  component: Component,
  location,
  ...rest
}: PrivateRouteProps) {
  if (!axios.isAuthenticated()) {
    return <Redirect to={createReturnUrl('/sign-in', location)} />;
  }

  return <Route {...rest} render={(props: any) => <Component {...props} />} />;
}
