import React, { ReactNode } from 'react';
import { Theme, Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

/*
   Simple wrapper for Typography component to prevent having to declare
   the same properties everywhere and allow some customization.
*/

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.secondary.main,
  },
}));

interface TypographyWarningProps extends TypographyProps {
  children: ReactNode;
}

function TypographyWarning({ children, ...other }: TypographyWarningProps) {
  const classes = useStyles();
  return (
    <Typography variant="subtitle1" {...other} className={classes.warning}>
      {children}
    </Typography>
  );
}

export default TypographyWarning;
