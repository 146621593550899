import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Tip from './Tip';

type TipProps = {
  [key: string]: any; // Adjust based on the actual `Tip` component props
};

type PrimaryButtonProps = {
  id: string;
  tip?: string;
  tipProps?: TipProps;
  children: ReactNode;
  [key: string]: any; // For additional props passed to the Button component
};

export default function PrimaryButton({
  id,
  tip = '',
  tipProps = {},
  children,
  ...rest
}: PrimaryButtonProps) {
  const button = (
    <Button id={id} variant="contained" color="primary" {...rest}>
      {children}
    </Button>
  );

  if (tip) {
    return (
      <Tip id={`${id}-tooltip`} title={tip} {...tipProps}>
        {button}
      </Tip>
    );
  }

  return button;
}
