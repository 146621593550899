import React from 'react';
import { styled } from '@mui/material';
import { pureWhite, bentoTeal2 } from './colors';

const StyleTooth = styled('div')<{ selected: boolean }>(({ selected }) => ({
  width: 40,
  height: 40,
  textAlign: 'center',
  color: selected ? pureWhite : bentoTeal2,
  backgroundColor: selected ? bentoTeal2 : pureWhite,
  border: `1px solid ${bentoTeal2}`,
  borderRadius: '50%',
  cursor: 'pointer',
  fontSize: 14,
  padding: '9px 0',
}));

interface ToothProps {
  tooth_select: Record<number, { selected: boolean }>;
  num: number;
  onToothClick: (num: number) => void;
}

function Tooth({ tooth_select: toothSelect, num, onToothClick }: ToothProps) {
  const isSelected = !!toothSelect[num]?.selected;

  return (
    <StyleTooth selected={isSelected} onClick={() => onToothClick(num)}>
      {num}
    </StyleTooth>
  );
}

export default Tooth;
