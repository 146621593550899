import React, { ReactNode } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { notify } from './Notification';

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: Infinity,
      onError: (error) => {
        notify((error as Error).message, 'error');
      },
    },
    mutations: {
      onError: (error) => {
        notify((error as Error).message, 'error');
      },
    },
  },
});

type QueryCacheProviderProps = {
  children: ReactNode;
};

export default function QueryCacheProvider({ children }: QueryCacheProviderProps) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
