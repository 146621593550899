const GENDER_TYPE_MALE = 'male';
const GENDER_TYPE_FEMALE = 'female';
const GENDER_TYPE_THIRD_GENDER = 'third gender';
const GENDER_TYPE_NOT_TO_SAY = 'not to say';
const GENDER_TYPE_SELF_DESCRIBE = 'self describe';
const TAX_ID_TYPE_BUSINESS_INDIVIDUAL = 'individual';
const TAX_ID_TYPE_BUSINESS_COMPANY = 'company';
const TAX_ID_TYPE_MEMBER_SSN = 'ssn';
const TAX_ID_TYPE_MEMBER_ITIN = 'itin';
const TAX_ID_TYPE_MEMBER_NONE = 'none';
const TAX_ID_TYPE_MEMBER_EMPLOYEE = 'employee';

const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  // Non-state territories
  AS: 'American Samoa',
  GU: 'Guam',
  MP: 'Northern Mariana Islands',
  PR: 'Puerto Rico',
  VI: 'Virgin Islands',
  // Military postal "states". Note that 'AE' encompasses Africa, Canada, Europe,
  // and the Middle East
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AP: 'Armed Forces Pacific',
  // Non-US locations serviced by USPS under Compact of Free Association
  FM: 'Federated States of Micronesia',
  MH: 'Marshall Islands',
  PW: 'Palau',
};

// Takes an object of the form { value: label } and gives a list that dropdowns can be happy with
const OptionsList = (obj: { [s: string]: unknown } | ArrayLike<unknown>) =>
  Object.entries(obj).map(([value, label]) => ({ label, value }));

const constants = {
  GENDER_TYPE_MALE,
  GENDER_TYPE_FEMALE,
  GENDER_TYPE_THIRD_GENDER,
  GENDER_TYPE_NOT_TO_SAY,
  GENDER_TYPE_SELF_DESCRIBE,
  GENDER_OPTIONS: [
    { value: GENDER_TYPE_MALE, label: 'Male' },
    { value: GENDER_TYPE_FEMALE, label: 'Female' },
    { value: GENDER_TYPE_THIRD_GENDER, label: 'Third Gender' },
    { value: GENDER_TYPE_NOT_TO_SAY, label: 'Prefer Not To Say' },
    { value: GENDER_TYPE_SELF_DESCRIBE, label: 'Self Describe' },
  ],
  SHORT_GENDER_OPTIONS: [
    { value: GENDER_TYPE_MALE, label: 'Male' },
    { value: GENDER_TYPE_FEMALE, label: 'Female' },
    { value: GENDER_TYPE_NOT_TO_SAY, label: 'Prefer Not To Say' },
  ],
  SPECIALITY_OPTIONS: [
    { value: 'general dentist', label: 'General Dentist' },
    { value: 'endodontist', label: 'Endodontist' },
    { value: 'oral surgeon', label: 'Oral Surgeon' },
    { value: 'orthodontist', label: 'Orthodontist' },
    { value: 'pediatric', label: 'Pediatric' },
    { value: 'periodontist', label: 'Periodontist' },
    { value: 'prosthodontist', label: 'Prosthodontist' },
  ],
  RELATIONSHIP_PRIMARY: 'Primary',
  RELATIONSHIP_SPOUSE: 'Spouse',
  RELATIONSHIP_DEPENDENT: 'Dependent',
  RELATIONSHIP_DEPENDENT_ADULT: 'Dependent Adult',
  RELATIONSHIP_LABEL_PRIMARY: 'Primary',
  RELATIONSHIP_LABEL_SPOUSE: 'Spouse',
  RELATIONSHIP_LABEL_DEPENDENT: 'Dependent',
  RELATIONSHIP_LABEL_DEPENDENT_ADULT: 'Adult Dependent',
  TAX_ID_TYPE_BUSINESS_INDIVIDUAL,
  TAX_ID_TYPE_BUSINESS_COMPANY,
  TAX_ID_BUSINESS_CHOICES: [TAX_ID_TYPE_BUSINESS_COMPANY, TAX_ID_TYPE_BUSINESS_INDIVIDUAL],
  TAX_ID_TYPE_BUSINESS_OPTIONS: [
    { value: TAX_ID_TYPE_BUSINESS_COMPANY, label: 'EIN' },
    { value: TAX_ID_TYPE_BUSINESS_INDIVIDUAL, label: 'SSN' },
  ],
  TAX_ID_TYPE_MEMBER_SSN,
  TAX_ID_TYPE_MEMBER_ITIN,
  TAX_ID_TYPE_MEMBER_NONE,
  TAX_ID_TYPE_MEMBER_EMPLOYEE,
  TAX_ID_MEMBER_CHOICES: [
    TAX_ID_TYPE_MEMBER_SSN,
    TAX_ID_TYPE_MEMBER_ITIN,
    TAX_ID_TYPE_MEMBER_NONE,
    TAX_ID_TYPE_MEMBER_EMPLOYEE,
  ],
  TAX_ID_MEMBER_OPTIONS: [
    { value: TAX_ID_TYPE_MEMBER_SSN, label: 'SSN' },
    { value: TAX_ID_TYPE_MEMBER_ITIN, label: 'ITIN' },
    { value: TAX_ID_TYPE_MEMBER_EMPLOYEE, label: 'Employer-Assigned ID' },
    { value: TAX_ID_TYPE_MEMBER_NONE, label: 'None' },
  ],
  TAX_ID_REQUIRED_OPTIONS: [
    { value: TAX_ID_TYPE_MEMBER_SSN, label: 'SSN' },
    { value: TAX_ID_TYPE_MEMBER_ITIN, label: 'ITIN' },
    { value: TAX_ID_TYPE_MEMBER_EMPLOYEE, label: 'Employer-Assigned ID' },
  ],
  // Based on RFC 5322 Official Standard - https://emailregex.com/
  REGEX: {
    EMAIL:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NPI: /^\d{10}$/,
    TID: /^(\d{9}|\d{2}-\d{7}|\d{3}-\d{2}-\d{4})$/,
  },
  US_STATES,
  STATES: Object.values(US_STATES),
  STATES_OPTIONS: OptionsList(US_STATES),
  VALIDATION: {
    REQUIRED: 'Required',
    INVALID: 'Invalid',
    TID: 'Must be 9 digits',
    TID4: 'Must be 4 digits',
    PHONE: 'Must be 10 digits',
    ZIP: 'Must be 5 digits',
    NPI: 'Must be 10 digits',
    PIN: 'Must be 6 digits',
    MEMBERID: 'Invalid member id',
    BANK_ACCOUNT: 'Must be 4 to 17 digits',
    BANK_ACCOUNT_MATCH: 'Bank account numbers must match',
    BANK_ROUTING: 'Must be 9 digits',
    BANK_ROUTING_MATCH: 'Bank routing numbers must match',
    EMAIL: 'Not a valid email',
    PASSWORD: 'Must be at least 6 characters',
    PASSWORD_MATCH: 'Passwords must match',
    NEW_PASSWORD_DIFFERENT: 'The new password should be different from the old password',
    PASSWORD_REQUIREMENTS:
      'Your password is too easy to guess. Please include both lower and uppercase letters and at least one number.',
    ADULT_REQUIRED: 'Age must be 18 years old or older',
    DENTIST_LICENSE: 'Must contain at least one digit',
    INVALID_DOB: 'Invalid date of birth',
    EIN_OR_SSN_REQUIRED: 'You must provide either your EIN or SSN ',
    EIN_SSN_ERROR: 'You must enter a value for only one of the fields EIN or SSN but not both',
  },
  ZIP_LENGTH: 5,
  TID_LENGTH: 9,
  PHONE_LENGTH: 10,
  NPI_LENGTH: 10,
  PIN_LENGTH: 6,
  PASSWORD_MIN_LENGTH: 6,
  BANK_ACCOUNT_MIN_LENGTH: 4,
  BANK_ACCOUNT_MAX_LENGTH: 17,
  BANK_ROUTING_LENGTH: 9,
  STANDARD_PLAN_NEW_PLAN: 'NEW_PLAN',
  STANDARD_PLAN_PLATINUM_PLUS: 'platinum_high',
  STANDARD_PLAN_PLATINUM_STANDARD: 'platinum_med',
  STANDARD_PLAN_PLATINUM_ESSENTIAL: 'platinum_low',
  STANDARD_PLAN_GOLD_PLUS: 'gold_high',
  STANDARD_PLAN_GOLD_STANDARD: 'gold_med',
  STANDARD_PLAN_GOLD_ESSENTIAL: 'gold_low',
  STANDARD_PLAN_SILVER_PLUS: 'silver_high',
  STANDARD_PLAN_SILVER_STANDARD: 'silver_med',
  STANDARD_PLAN_SILVER_ESSENTIAL: 'silver_low',
  STANDARD_PLAN_TYPES: [
    'platinum_high',
    'platinum_med',
    'platinum_low',
    'gold_high',
    'gold_med',
    'gold_low',
    'silver_high',
    'silver_med',
    'silver_low',
  ],
  MIN_BENEFIT_DATE: '01-01-1970',
  NETWORK_PLATINUM: 'platinum',
  NETWORK_GOLD: 'gold',
  NETWORK_SILVER: 'silver',
  NETWORK_OFFICE_FEES: 'office_fees',
  NETWORKS: ['platinum', 'gold', 'silver', 'office_fees'],
  NETWORKS_MAP: {
    platinum: 'Platinum',
    gold: 'Gold',
    silver: 'Silver',
    office_fees: 'Office Fees',
  },
  MINIMUM_NETWORKS_MAP: {
    platinum: 'Platinum',
    gold: 'Platinum, Gold',
    silver: 'Platinum, Gold, Silver',
    office_fees: 'Office Fees',
  },
  MINIMUM_NETWORKS_SILVER_REMOVED_MAP: {
    platinum: 'Platinum',
    gold: 'Platinum, Gold',
    office_fees: 'Office Fees',
  },
  NETWORK_TYPES: {
    OUT_OF_NETWORK: 'out_of_network',
    PARTNER: 'partner',
    BENTO: 'bento',
  },
  LOCATION_TYPES: {
    OUT_OF_NETWORK: 'out_of_network',
    PARTNER: 'partner',
    BENTO: 'bento',
  },
  BANK_STATUS_OWNER_NOT_ADDED: 'BANK_STATUS_OWNER_NOT_ADDED',
  BANK_STATUS_ACCOUNT_NOT_ADDED: 'BANK_STATUS_ACCOUNT_NOT_ADDED',
  BANK_STATUS_VERIFICATION_PENDING: 'BANK_STATUS_VERIFICATION_PENDING',
  BANK_STATUS_VERIFIED: 'BANK_STATUS_VERIFIED',
  PORTALS: {
    ADMIN: 'admin',
    DENTIST: 'dentist',
    EMPLOYER: 'employer',
    MEMBER: 'member',
  },
  TIP_IS_EDI: 'This feature is disabled for companies using EDI.',
  TIP_HAS_UNVERIFIED_BANK: 'This feature is enabled after you verify your bank.',
  PRACTICE_TYPE_BENTO: 'bento',
  PRACTICE_TYPE_PARTNER: 'partner',
  PRACTICE_TYPE_OUT_OF_NETWORK: 'out_of_network',
  IDENTITY_VERIFICATION_STATUS_OPTIONS: {
    UNVERIFIED: 'Unverified',
    FAILED: 'Failed',
    VERIFIED: 'Verified',
  },
  ReportDateType: {
    APPOINTMENT_DATE: 'appointment_date',
    PAYMENT_DATE: 'payment_date',
  },
  AppointmentViewType: {
    ALL: 'all',
    APPOINTMENTS: 'appointments',
    ESTIMATES: 'estimates',
  },
  SERVICE_TYPE: [
    { value: 'BRS', label: 'BRS' },
    { value: 'MRS', label: 'MRS' },
    { value: 'PDS', label: 'PDS' },
  ],
  ORTHO_OVERRIDE_OPTIONS: [
    { value: null, label: '<use default>' },
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  CUSTOMIZED_PLAN_NETWORKS: ['platinum', 'gold', 'silver'],
  WEEKDAYS: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
};

export default constants;
