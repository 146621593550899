import { useReducer, useEffect, useCallback, ChangeEvent } from 'react';

type FormState<T> = {
  [K in keyof T]: T[K];
};

const useFormState = <T>(initialFormState: T) => {
  const [formState, setFormState] = useReducer(
    (prevState: T, state: Partial<T>) => ({ ...prevState, ...state }),
    initialFormState
  );

  useEffect(() => {
    setFormState(initialFormState);
  }, [initialFormState]);

  return {
    formState,
    setFormState,
    handleChange: (name: keyof T) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const target = e.target as HTMLInputElement;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      setFormState({ [name]: value } as Partial<T>);
    },
    resetFormState: useCallback(() => {
      setFormState(initialFormState);
    }, [initialFormState]),
  };
};

export default useFormState;
