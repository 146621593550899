import React, { ReactNode, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  styled,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import EnvironmentBanner, { EnvironmentBannerProps } from './EnvironmentBanner';
import NavMenuItem from './NavMenuItem';
import { pureWhite, bentoSlate1 } from './colors';
import BentoLogoDark from '../public/bento-logo-dark.png';
import BentoLogoSmall from '../public/bento-logo-small.svg';
import BentoIcon from './BentoIcon';

const drawerWidth = 176;
const appBarHeight = 56;

const LogoImg = styled('img')({ aspectRatio: 2.85, height: 50 });

type LinkItem = {
  title: string;
  id: string;
  route: string | string[];
  items?: LinkItem[];
};

type AppLayoutProps = {
  children: ReactNode;
  links: LinkItem[];
  warnsFor: boolean;
  hideLayout?: boolean;
  hideLayoutUrls?: string[];
  envProps: EnvironmentBannerProps;
  header?: ReactNode;
  footer?: ReactNode;
};

export default function AppLayout({
  children,
  links,
  warnsFor,
  hideLayout = false,
  hideLayoutUrls = [],
  envProps,
  header,
  footer,
}: AppLayoutProps) {
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen((prevOpen) => !prevOpen);
  };

  const shouldHideLayout = useMemo(
    () => hideLayout || hideLayoutUrls.includes(location.pathname),
    [hideLayout, hideLayoutUrls, location.pathname]
  );

  if (shouldHideLayout) {
    return <>{children}</>;
  }

  const { debug, ...bannerProps } = envProps;

  const drawerContents = [
    !isSmallScreen && (
      <img src={BentoLogoDark} alt="Bento" width={160} height={56} key="logo-large" />
    ),
    <Box display={{ xs: 'flex', sm: 'none' }} key="logo-small">
      <LogoImg src={BentoLogoDark} alt="Bento" />
      <IconButton
        onClick={() => setMobileOpen(false)}
        sx={{ paddingLeft: 0.75, color: '#fff' }}
        size="large"
      >
        <ExpandLessIcon />
      </IconButton>
    </Box>,
    <List sx={{ padding: 0 }} key="nav-list">
      {links.map(({ title, id, route, items }) => {
        if (id === 'divider') {
          return <Divider key={id} margin={2} borderColor={pureWhite} />;
        }

        const isLinkActive = Array.isArray(route)
          ? route.includes(location.pathname)
          : location.pathname.startsWith(route);

        return (
          <NavMenuItem
            id={id}
            key={title}
            title={title}
            route={route}
            isActive={isLinkActive}
            items={items}
            showWarningFor={warnsFor}
            onClick={() => {
              if (mobileOpen) {
                setMobileOpen(false);
              }
            }}
          />
        );
      })}
    </List>,
    debug && <EnvironmentBanner {...bannerProps} key="env-banner" />,
  ];

  return (
    <>
      <Box display="flex">
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            height: `${appBarHeight}px`,
            paddingX: { xs: 0, sm: 1 },
            paddingY: 0,
          }}
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingLeft={{ xs: 'none', sm: 20 }}
          >
            <Box display="flex" alignItems="center">
              <Box bgcolor="#202E39" display={{ xs: 'inline', sm: 'none' }}>
                <img src={BentoLogoSmall} alt="Bento" width={50} />
              </Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                size="large"
                sx={{ marginLeft: 0, display: { xs: 'block', sm: 'none' } }}
              >
                <BentoIcon name="bars" fontSize="1.8rem" />
              </IconButton>
            </Box>
            <Box width="100%">{header}</Box>
          </Box>
        </AppBar>
        <Drawer
          variant={isSmallScreen ? 'temporary' : 'permanent'}
          open={isSmallScreen ? mobileOpen : true}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 'inherit',
              padding: 0,
              backgroundColor: bentoSlate1,
              color: '#81cbf1',
              overflowX: 'hidden',
            },
          }}
        >
          {drawerContents}
        </Drawer>
        <Box
          component="main"
          flexGrow={1}
          maxWidth="100vw"
          minHeight="94vh"
          marginTop={`${appBarHeight}px`}
        >
          {children}
        </Box>
      </Box>
      {footer}
    </>
  );
}
