import { ChangeEvent } from 'react';
import isEmpty from '../utilities/isEmpty';
import useFormState from './useFormState';
import useFormErrors from './useFormErrors';

// Define types for the props and other elements
type ValidateFunction<T> = (formState: T) => Record<string, string>;
type GetValueFunction = (name: string, value: any) => any;

interface UseFormHandlerProps<T> {
  defaultState: T;
  validate?: ValidateFunction<T>;
  getValue?: GetValueFunction;
}

export default function useFormHandler<T>({
  defaultState,
  validate,
  getValue,
}: UseFormHandlerProps<T>) {
  const { errors, setErrors, clearError } = useFormErrors();
  const { formState, setFormState } = useFormState<T>(defaultState);

  function resetFormState(values: Partial<T> = {}): void {
    setFormState({ ...defaultState, ...values });
    setErrors({});
  }

  function validateInput(): boolean {
    if (validate) {
      const formErrors = validate(formState);
      const isValid = isEmpty(formErrors);
      if (!isValid) {
        setErrors(formErrors);
      }
      return isValid;
    }
    return true;
  }

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    if (!e.target) {
      return;
    }
    const { name, value, type, checked } = e.target as HTMLInputElement;
    const keyName = name || value;
    const keyValue = type === 'checkbox' ? checked : value;
    const newValue = getValue ? getValue(name, value) : keyValue;
    setFormState({ [keyName]: newValue } as Partial<T>);
    clearError(keyName);
  }

  return {
    formState,
    setFormState,
    resetFormState,
    handleChange,
    validate: validateInput,
    errors,
    clearError,
    clearErrors: (): void => setErrors({}),
  };
}
